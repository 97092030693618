import { useNavigate } from "react-router-dom";

function Header() {

  const Signout=()=>{
    localStorage.clear("user_info");
    navigate("/");

  }

  const navigate = useNavigate();
  return (
    <header className="navbar sticky-top flex-md-nowrap" style={{background: "#f5f5f5 !important"}}>
      <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="#"></a>
      <button
        className="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="navbar-nav">
        <div className="nav-item text-nowrap">
          <button className="btn btn-primary" onClick={Signout}>
            Sign out
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
