import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

import "react-toastify/dist/ReactToastify.css";
import "./pagelayout.css";
import TableWithCheckboxes from "./elementfiles/TableWithCheckboxes";

function CreateListPageLayout() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [linkPopup, setLinkPopup] = useState(false);
  const { page_id } = useParams();
  const [listPageLayoutId, setListPageLayoutId] = useState();
  const [entityfields, setEntityFields] = useState([]);
  const [pageDetails, setPageDetails] = useState([]);
  const [pages, setPages] = useState([]);
  const [pagesDictionary, setPagesDictionary] = useState({});
  const [listPageLayout, setListPageLayout] = useState({
    buttons: [],
    listPageLinks: [],
    listPageMainContent: [],
  });
  const [listPageMainContent, setListPageMainContent] = useState([]);

  const [entityFieldsDictionary, setEntityFieldsDictionary] = useState({});
  const [editButtonIndex, setEditButtonIndex] = useState(0);
  const [editPageLinkIndex, setEditPageLinkIndex] = useState(0);
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
  const [isEditPageLinkIndex, setIsEditPageLinkIndex] = useState(false);

  let [buttonDetails, setButtonDetails] = useState({
    name: "",
    description: "",
    number: 0,
    redirectToPageId: "",
  });
  let [buttons, setButtons] = useState([]);

  let [columnDetails, setColumnDetails] = useState({
    columnNumber: 0,
    fieldLabel: "",
    mappedEntityFieldId: "",
  });
  let [columns, setColumns] = useState([]);

  let [pageLinkDetails, setPageLinkDetails] = useState({
    name: "",
    description: "",
    number: 0,
    redirectToPageId: "",
    entityFieldToUse: "",
  });
  let [pageLinks, setPageLinks] = useState([]);

  useEffect(() => {
    setLoading(true);
    getPageDetails();
    getListPageLayoutData(page_id);
    setLoading(false);
  }, []);

  const editButton = (index) => {
    setEditButtonIndex(index);
    setIsEditButtonClicked(true);
    setButtonPopup(true);
    setButtonDetails({
      name: listPageLayout.buttons[index].name,
      description: listPageLayout.buttons[index].description,
      number: listPageLayout.buttons[index].number,
      redirectToPageId: listPageLayout.buttons[index].redirectToPageId,
    });
  };

  const editPageLink = (index) => {
    setEditPageLinkIndex(index);
    setIsEditPageLinkIndex(true);
    setLinkPopup(true);
    setPageLinkDetails({
      name: listPageLayout.listPageLinks[index].name,
      description: listPageLayout.listPageLinks[index].description,
      number: listPageLayout.listPageLinks[index].number,
      redirectToPageId: listPageLayout.listPageLinks[index].redirectToPageId,
      entityFieldToUse: listPageLayout.listPageLinks[index].entityFieldToUse,
    });
  };

  const addButton = () => {
    setButtonPopup(true);
    setButtonDetails({
      name: "",
      description: "",
      number: "",
      redirectToPageId: "",
    });
  };

  const addPageLink = () => {
    setLinkPopup(true);
    setPageLinkDetails({
      name: "",
      description: "",
      number: "",
      redirectToPageId: "",
      entityFieldToUse: "",
    });
  };
  const [selectedItems, setSelectedItems] = useState([]); // To store entered data
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);

  const getEntityFields = async (entityId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/entity-field/entity/${entityId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setEntityFields(response.data);
        let entityFieldsDict = {};
        response.data.map((obj, index) => {
          entityFieldsDict[obj.id] = obj.name;
        });
        setEntityFieldsDictionary(entityFieldsDict);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getPageDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/page/${page_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setPageDetails(response.data);
        getEntityFields(response.data["mappedEntityId"]);
        getPages(response.data["projectId"]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getPages = async (projectId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/page/project/${projectId}/all`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setPages(response.data);
        let d = {};
        response.data.map((obj, index) => {
          d[obj.id] = obj.name;
        });
        setPagesDictionary(d);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Get list page layout
  const getListPageLayoutData = async (pageId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/list-page/page/${pageId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      );
      if (response.data != "") {
        setListPageLayout(response.data);
        setListPageLayoutId(response.data.id);
        setListPageMainContent(response.data.listPageMainContent);
      }
    } catch (error) { }
  };

  const addListPageDetails = () => {
    var dataToPost = {
      pageId: page_id,
      buttons: listPageLayout.buttons,
      listPageMainContent: listPageMainContent,
      listPageLinks: listPageLayout.listPageLinks,
    };
    if (listPageLayoutId) {
      dataToPost.id = listPageLayoutId;
    }
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/list-page`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      data: dataToPost,
    };
    axios(config);
  };

  const handleButtonDetailsValueChange = (prop) => (event) => {
    const { name, value } = event.target;
    setButtonDetails({ ...buttonDetails, [prop]: event.target.value });
  };

  const handleChangeButtonRedirectPageIdComponent = (prop) => (event) => {
    setButtonDetails({ ...buttonDetails, [prop]: event.target.value });
  };

  const handlePageLinkDetailsValueChange = (prop) => (event) => {
    const { name, value } = event.target;
    setPageLinkDetails({ ...pageLinkDetails, [prop]: event.target.value });
  };

  const handlePageLinkRedirectPageIdComponentChange = (prop) => (event) => {
    setPageLinkDetails({ ...pageLinkDetails, [prop]: event.target.value });
  };

  const handlePageLinkEntityIdComponentChange = (prop) => (event) => {
    setPageLinkDetails({ ...pageLinkDetails, [prop]: event.target.value });
  };

  const savePageButton = () => {
    if (isEditButtonClicked) {
      listPageLayout.buttons[editButtonIndex] = buttonDetails;
    } else {
      if (
        listPageLayout.buttons == undefined ||
        listPageLayout.buttons.length == 0
      ) {
        listPageLayout.buttons = [];
      }
      listPageLayout.buttons.push(buttonDetails);
    }
    setIsEditButtonClicked(false);
    setButtonPopup(false);
  };

  const savePageLink = () => {
    if (isEditPageLinkIndex) {
      listPageLayout.listPageLinks[editPageLinkIndex] = pageLinkDetails;
    } else {
      if (
        listPageLayout.buttons == undefined ||
        listPageLayout.listPageLinks.length == 0
      ) {
        listPageLayout.listPageLinks = [];
      }
      listPageLayout.listPageLinks.push(pageLinkDetails);
    }

    setIsEditPageLinkIndex(false);
    setLinkPopup(false);
  };

  const saveDetails = () => {
    buttons.push(buttonDetails);
    pageLinks.push(pageLinkDetails);
    addListPageDetails();
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
          <NavigationBar />
          <div className="col-md-9">
            <h3>{pageDetails.name}</h3>
            <hr />

            <div className="row">
              <div className="col-md-9">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        to={
                          "/project/" +
                          localStorage.getItem("project_id") +
                          "/pages"
                        }
                      >
                        Pages
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">Create New Page</li>
                  </ol>
                </nav>
              </div>
            </div>
            <hr />
            <div className="row">
              <div id="main-content" className="col-md-12">
                <div style={{ marginBottom: "50px" }} id="addRow">
                  <div className="row">
                    <div className="col-md-9">
                      <h5>Page Buttons</h5>
                    </div>
                    <div className="col-md-3">
                      <div class="form-group">
                        <button
                          type="button"
                          style={{ padding: "5px", borderRadius: "4px" }}
                          onClick={addButton}
                        >
                          Add Button
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="col-md-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Number</th>
                          <th>Name</th>
                          <th>Redirect to page</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listPageLayout &&
                          listPageLayout.buttons &&
                          listPageLayout.buttons.map((item, index) => {
                            return (
                              <tr key={index + 1}>
                                <td>{item.number}</td>
                                <td>{item.name}</td>
                                <td>
                                  {pagesDictionary[item.redirectToPageId]}
                                </td>
                                <td>
                                  <Link onClick={() => editButton(index)}>
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div style={{ marginBottom: "50px" }} id="addRow">
                  <div className="row">
                    <div className="col-md-9">
                      <h5>Page Links</h5>
                    </div>
                    <div className="col-md-3">
                      <div class="form-group">
                        <button
                          type="button"
                          style={{ padding: "5px", borderRadius: "4px" }}
                          onClick={addPageLink}
                        >
                          Add Link
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Number</th>
                          <th>Name</th>
                          <th>Redirect to page</th>
                          <th>Entity field to get data</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listPageLayout &&
                          listPageLayout.listPageLinks &&
                          listPageLayout.listPageLinks.map((item, index) => {
                            return (
                              <tr key={index + 1}>
                                <td>{item.number}</td>
                                <td>{item.name}</td>
                                <td>
                                  {pagesDictionary[item.redirectToPageId]}
                                </td>
                                <td>
                                  {
                                    entityFieldsDictionary[
                                    item.entityFieldToUse
                                    ]
                                  }
                                </td>
                                <td>
                                  <Link onClick={() => editPageLink(index)}>
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <hr />
                <h5>Add Table Columns</h5>
                <hr />
                <div style={{ marginBottom: "50px" }} id="addRow">
                  <TableWithCheckboxes
                    data={entityfields}
                    listPageMainContent={listPageMainContent}
                    onChange={setListPageMainContent}
                  />
                </div>
                <div className="col-md-4">
                  <button
                    type="submit"
                    style={{ padding: "5px", borderRadius: "4px" }}
                    onClick={saveDetails}
                  >
                    Save Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Page Button Dialog */}
      <Dialog
        open={buttonPopup}
        onClose={() => setButtonPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: "70%", height: "70%" } }}
      >
        <DialogTitle id="alert-dialog-title">Add Button</DialogTitle>
        <DialogContent>
          <form role="form">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="buttonNameInput">Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="number"
                    value={buttonDetails.number}
                    onChange={handleButtonDetailsValueChange("number")}
                    onBlur={handleButtonDetailsValueChange("number")}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="buttonNameInput">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={buttonDetails.name}
                    onChange={handleButtonDetailsValueChange("name")}
                    onBlur={handleButtonDetailsValueChange("name")}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="buttonNameInput">Redirect To Page</label>
                  <div>
                    <select
                      labelId="demo-simple-select-label"
                      id="redirectToPageId"
                      value={buttonDetails.redirectToPageId}
                      label="Entities"
                      className="form-select"
                      defaultValue={buttonDetails.redirectToPageId}
                      onChange={handleChangeButtonRedirectPageIdComponent(
                        "redirectToPageId"
                      )}
                    >
                      {pages.map((page) => {
                        return (
                          <option name={page.id} value={page.id}>
                            {page.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="buttonNameInput">&nbsp;</label>
                  <button
                    id="addPageLinkBtn"
                    type="button"
                    style={{ padding: "5px", borderRadius: "4px" }}
                    onClick={savePageButton}
                  >
                    Add Button
                  </button>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* Page Link Dialog */}
      <Dialog
        open={linkPopup}
        onClose={() => setLinkPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: "70%", height: "70%" } }}
      >
        <DialogTitle id="alert-dialog-title">Add Page Link</DialogTitle>
        <DialogContent>
          <form role="form">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="buttonNameInput">Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="number"
                    value={pageLinkDetails.number}
                    onChange={handlePageLinkDetailsValueChange("number")}
                    onBlur={handlePageLinkDetailsValueChange("number")}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="buttonNameInput">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    value={pageLinkDetails.name}
                    onChange={handlePageLinkDetailsValueChange("name")}
                    onBlur={handlePageLinkDetailsValueChange("name")}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label ltmlFor="buttonNameInput">Redirect To Page</label>
                  <div>
                    <select
                      labelId="demo-simple-select-label"
                      id="redirectToPageId"
                      className="form-select"
                      value={pageLinkDetails.redirectToPageId}
                      label="Entities"
                      defaultValue={pageLinkDetails.redirectToPageId}
                      onChange={handlePageLinkRedirectPageIdComponentChange(
                        "redirectToPageId"
                      )}
                    >
                      {pages.map((page) => {
                        return (
                          <option name={page.id} value={page.id}>
                            {page.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="buttonNameInput">Entity to get data</label>
                  <div>
                    <select
                      labelId="demo-simple-select-label"
                      id="entityFieldToUse"
                      className="form-select"
                      value={pageLinkDetails.entityFieldToUse}
                      label="Entities"
                      defaultValue={pageLinkDetails.entityFieldToUse}
                      onChange={handlePageLinkEntityIdComponentChange(
                        "entityFieldToUse"
                      )}
                    >
                      {entityfields.map((entity) => {
                        return (
                          <option name={entity.id} value={entity.id}>
                            {entity.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="buttonActionInput">Action</label>
                  <input
                    type="text"
                    class="form-control"
                    id="action"
                    value={pageLinkDetails.name}
                    onChange={handlePageLinkDetailsValueChange("action")}
                    onBlur={handlePageLinkDetailsValueChange("action")}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="buttonAdditionalInfoInput">Action</label>
                  <input
                    type="text"
                    class="form-control"
                    id="additionalInfo"
                    value={pageLinkDetails.additionalInfo}
                    onChange={handlePageLinkDetailsValueChange("additionalInfo")}
                    onBlur={handlePageLinkDetailsValueChange("additionalInfo")}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="buttonNameInput">&nbsp;</label>
                  <button
                    id="addPageLinkBtn"
                    type="button"
                    style={{ padding: "5px", borderRadius: "4px" }}
                    onClick={savePageLink}
                  >
                    Add Page Link
                  </button>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateListPageLayout;
