import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function EditOrganizationMember() {
  const { organization_id } = useParams();
  const { organization_member_id } = useParams();
  const navigate = useNavigate();
  const navigateToOrganizationMembers = () => {
    navigate(
      "/organization-members/" + localStorage.getItem("organization_id_members")
    );
  };
  let [loading, setLoading] = useState(true);
  let [organizationMember, setOrganizationMember] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    organizationId: "",
    memberRoles: [],
    isAdmin: false,
  });

  const handleChange = (prop) => (event) => {
    const { name, value } = event.target;
    setOrganizationMember({
      ...organizationMember,
      [prop]: event.target.value,
    });
  };

  useEffect(() => {
    getOrganizationMemberDetails(organization_id);
    setOrganizationMember({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
      organizationId: "",
      memberRoles: [],
      isAdmin: false,
    });
    setLoading(false);
  }, []);

  // Get OrganizationMember Details

  const getOrganizationMemberDetails = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/organization-member/${organization_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      )
      .then((response) => {
        setOrganizationMember({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          password: response.data.password,
          phoneNumber: response.data.phoneNumber,
          organizationId: localStorage.getItem("organization_id_members"),
          memberRoles: response.data.memberRoles,
          isAdmin: response.data.isAdmin,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Add OrganizationMember
  const AddOrganizationMember = async () => {
    setLoading(true);

    let result = await fetch(`${process.env.REACT_APP_API_URL}/organization-member/`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      body:JSON.stringify({
        id: organization_id,
        firstName: organizationMember.firstName,
        lastName: organizationMember.lastName,
        email: organizationMember.email,
        password: organizationMember.password,
        phoneNumber: organizationMember.phoneNumber,
        organizationId: localStorage.getItem("organization_id_members"),
        memberRoles: organizationMember.memberRoles,
        isAdmin: organizationMember.isAdmin,
        createdBy: JSON.parse(localStorage.getItem("user_info")).id,
      }),
    }
  );
  
  if (result.status==200) {   
    navigate("/organization-members/" + localStorage.getItem("organization_id_members"))
  } 
  else if(result.status==400){
    alert("Bad request")
    }
  else{
    localStorage.clear("user_info");
    navigate("/");
  }
  
  };



 
  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Organization Member</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`/organization-members/${localStorage.getItem(
                        "organization_id_members"
                      )}`}
                    >
                      Organization Members
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create New Organization Member
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />

          <form role="form">
            <h4>Basic Information</h4>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    value={organizationMember.firstName}
                    onChange={handleChange("firstName")}
                    onBlur={handleChange("firstName")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    value={organizationMember.lastName}
                    onChange={handleChange("lastName")}
                    onBlur={handleChange("lastName")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      value={organizationMember.email}
                      onChange={handleChange("email")}
                      onBlur={handleChange("email")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={organizationMember.password}
                      onChange={handleChange("password")}
                      onBlur={handleChange("password")}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNumber"
                      value={organizationMember.phoneNumber}
                      onChange={handleChange("phoneNumber")}
                      onBlur={handleChange("phoneNumber")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="isAdmin"
                      value={organizationMember.isAdmin}
                      onChange={handleChange("isAdmin")}
                      onBlur={handleChange("isAdmin")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Is Admin
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                <button
                  type="button"
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                  onClick={AddOrganizationMember}
                >
                  Update
                </button>
                <button
                  type="button"
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                  onClick={navigateToOrganizationMembers}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditOrganizationMember;
