import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListHTMLComponents() {
  let [loading, setLoading] = useState(false);
  let [htmlComponents, setHtmlComponents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getHTMLComponentsData();
  }, []);

  const navigateToCreateHTMLComponent = (id) => {
    navigate("/html-component/view/" + id);
  };


  // List HTML Components
  const getHTMLComponentsData = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/html-component`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setHtmlComponents(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };


  return (
    /* createdAt, createdBy, id, name*/
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>HTML Components</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
            </div>
            <div className="col-md-3">
              <button
                style={{cursor: "pointer",marginRight:"5px", padding:"5px", borderRadius:"4px"}}
                onClick={navigateToCreateHTMLComponent}
              >
                Add New HTML Component
              </button>
            </div>
          </div>

          <hr />

          <table className="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {htmlComponents.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <Link to={`/html-component/view/${item.id}`}>
                        <button
                          type="button"
                        
                          style={{cursor: "pointer",marginRight:"5px", padding:"5px", borderRadius:"4px"}}
                        >
                          Edit
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default ListHTMLComponents;
