import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import "../btn/btn.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListOrganizationMembers() {
  const { organization_id } = useParams();
  const navigate = useNavigate();
  const navigateToCreateOrganizationMember = () => {
    navigate("/create-organization-member/" + organization_id);
  };
  const navigateOrganizations = () => {
    navigate("/organizations");
  };

  let [loading, setLoading] = useState(false);
  let [organizationMembers, setOrganizationMembers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // const [totalPages, setTotalPages] = useState(0);
  // const [pageNumber, setPageNumber] = useState(0);
  // const [isFirstPageNumber, setIsFirstPageNumber] = useState(true);
  // const [isLastPageNumber, setIsLastPageNumber] = useState(false);

  useEffect(() => {
    localStorage.setItem("organization_id_members", organization_id);

    const getOrganizationMembersData = async () => {
      setLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/organization-member/organization/${organization_id}?page=0`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("user_info")).accessToken,
            },
          }
        )
        .then((response) => {
          setOrganizationMembers(response.data.content);
          const total = response.data.totalElements;
          console.log("total", total);
          setPageCount(Math.ceil(total / 10));
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };
    getOrganizationMembersData();
  }, []);

  // List organization members data

  const fetchOrganizationsMembers = async (currentPage) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/organization-member/organization/${organization_id}?page=${currentPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected;
    const dataFromServer = await fetchOrganizationsMembers(currentPage);
    setOrganizationMembers(dataFromServer.content);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Organization Members</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li
                    className="breadcrumb-item"
                    onClick={navigateOrganizations}
                  >
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Organization Members
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-3">
              <button
                style={{ padding: "5px", borderRadius: "4px" }}
                onClick={navigateToCreateOrganizationMember}
              >
                Add New Organization Member
              </button>
            </div>
          </div>

          <hr />

          <table className="table">
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email Id</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {organizationMembers.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.email}</td>
                    <td>
                      {/* <Link to={`/organization-member-details/${organization_id}/${item.id}`}>
                                            <button type="button" className="btn btn-success">Edit</button> 
                                            </Link> */}
                      <Link
                        to={`/organization-member/editorganizationmember/${item.id}`}
                      >
                        <button
                          type="button"
                          style={{ padding: "5px", borderRadius: "4px" }}
                        >
                          Edit
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <ReactPaginate
            breakLabel="..."
            previousLabel="< previous"
            nextLabel="next >"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center "}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default ListOrganizationMembers;
