import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function QuickPageLayout() {
  const { entityId } = useParams();
  const { pageId } = useParams();
  const { project_id } = useParams();
  const navigate = useNavigate();
  const navigateToCreateEntity = () => {
    navigate("/create-entity/" + project_id);
  };
  const navigateProjects = () => {
    navigate("/projects");
  };
  const navigatePagesByEntity = (entityId) => {
    navigate("/pages/entities/" + entityId);
  };

  let [loading, setLoading] = useState(false);
  let [pageFields, setPageFields] = useState([]);
  let [entityFields, setEntityFields] = useState([]);
  const [htmlElementData, setHtmlElementData] = useState([]);

  useEffect(() => {
    getEntityFields();
  }, []);

  const getEntityFields = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/entity-field/entity/${entityId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setEntityFields(response.data);
        getPageFields(pageId);
        getHtmlElementData();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getPageFields = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/page-field/fields/${pageId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setPageFields(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getHtmlElementData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/html-component`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setHtmlElementData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const addNewRow = () => {
    pageFields.push({
      id: "1",
      rowNumber: 3,
      columnNumber: 0,
      fieldLabel: "A",
      mappedEntityFieldId: "code",
      htmlComponentId: "Textbox",
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Page Layout</h2>
          <hr />

          <div className="row">
            <div className="col-md-8">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>

                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/project-details/" + localStorage.getItem("project_id")
                      }
                    >
                      Details
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={"/entities/" + localStorage.getItem("project_id")}
                    >
                      Entities
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to={"/pages/entities/" + pageId}>Pages</Link>
                  </li>
                  <li className="breadcrumb-item active">Page Layout</li>
                </ol>
              </nav>
            </div>
          </div>

          <hr />

          <table className="table">
            <thead>
              <tr>
                <th>Row Number</th>
                <th>Column Number</th>
                <th>Field Label</th>
                <th>Mapped Entity Field Id</th>
                <th>HTML Component Id</th>
              </tr>
            </thead>
            <tbody>
              {pageFields &&
                pageFields.map((field, index) => {
                  return (
                    <tr>
                      <td style={{ width: "5%" }}>
                        <input
                          type="text"
                          className="form-control"
                          id={field.id}
                          value={field.rowNumber}
                        ></input>
                      </td>
                      <td style={{ width: "5%" }}>
                        <input
                          type="text"
                          className="form-control"
                          id={field.id}
                          value={field.columnNumber}
                        ></input>
                      </td>
                      <td style={{ width: "30%" }}>
                        <input
                          type="text"
                          className="form-control"
                          id={field.id}
                          value={field.fieldLabel}
                        ></input>
                      </td>
                      <td style={{ width: "25%" }}>
                        <select
                          className="form-select"
                          labelId="demo-simple-select-label"
                          id="mappedEntityFieldId"
                          value={field.mappedEntityFieldId}
                          label="Entity Fields"
                          style={{ width: "100%" }}
                        >
                          {entityFields.map((data, index) => {
                            return (
                              <option key={index} value={data.name}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td style={{ width: "25%" }}>
                        <select
                          className="form-select"
                          labelId="demo-simple-select-label"
                          id="htmlComponentId"
                          value={field.htmlComponentId}
                          label="HTML Component"
                          style={{ width: "100%" }}
                        >
                          {htmlElementData.map((data, index) => {
                            return (
                              <option key={index} value={data.name}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <button onClick={() => addNewRow()}>Add New Row</button>
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default QuickPageLayout;
