import axios from "axios";
import * as FileSaver from "file-saver";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListTemplates() {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [isFirstPageNumber, setIsFirstPageNumber] = useState(true);
  const [isLastPageNumber, setIsLastPageNumber] = useState(false);

  useEffect(() => {
    setLoading(true);
    getTemplates();
  }, []);

  // List Templates
  const getTemplates = async (pNumber) => {
    let page = pageNumber;
    if (pNumber >= 0) {
      page = pNumber;
    }
    await axios
      .get(`${process.env.REACT_APP_API_URL}/templates?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setTemplates(response.data.content);
        setTotalPages(response.data.totalPages);
        setIsFirstPageNumber(response.data.first);
        setIsLastPageNumber(response.data.last);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Get bulk data upload template
  const downloadBulkDataUploadTemplate = async (templateId, templateName) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/templates/${templateId}/download`,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      )
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, templateName + ".xlsx");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Templates</h2>
          <hr />

          <table className="table">
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {templates &&
                templates.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <Link to={`/templates/details/${item.id}`}>
                          <button type="button" className="btn btn-success">
                            View
                          </button>
                        </Link>
                        &nbsp;
                        <Link
                          onClick={() =>
                            downloadBulkDataUploadTemplate(item.id, item.name)
                          }
                        >
                          <button type="button" className="btn btn-success">
                            Download
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {totalPages > 0 && (
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                {isFirstPageNumber ? (
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">
                      Previous
                    </a>
                  </li>
                ) : (
                  <li class="page-item enabled">
                    <a class="page-link" href="#" tabindex="-1">
                      Previous
                    </a>
                  </li>
                )}
                {Array.from({ length: totalPages }, (_, i) => (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={() => {
                        setPageNumber({ i });
                      }}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
                {isLastPageNumber ? (
                  <li class="page-item disabled">
                    <a class="page-link" href="#">
                      Next
                    </a>
                  </li>
                ) : (
                  <li class="page-item">
                    <a class="page-link" href="#">
                      Next
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          )}
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default ListTemplates;
