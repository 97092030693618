const TextArea = ({ field_id, fieldLabel, mappedEntityFieldId }) => {
  return (
    <div className="form-group">
      <label htmlFor="exampleFormControlTextarea1">{fieldLabel}</label>
      <textarea
        className="form-control"
        id="exampleFormControlTextarea1"
        rows="3"
      >
        {mappedEntityFieldId}
      </textarea>
    </div>
  );
};
export default TextArea;
