import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
// import Login from "./auth/login";

import DatabaseSetting from "./database-setting/DatabaseSettings";
import AddEntity from "./entities/AddEntity";
import ListEntities from "./entities/ListEntities";
import AddEntityField from "./entity-fields/AddEntityField";
import EditEntityField from "./entity-fields/EditEntityField";
import ListEntityFields from "./entity-fields/ListEntityFields";
import AddMenu from "./menus/AddMenu";
import EditMenu from "./menus/EditMenu";
import ListMenus from "./menus/ListMenus";
import AddOrganizationMember from "./organization-member/AddOrganizationMember";
import EditOrganizationMember from "./organization-member/EditOrganizationMember";
import ListOrganizationMembers from "./organization-member/ListOrganizationMembers";
import AddOrganization from "./organizations/AddOrganization";
import EditOrganization from "./organizations/EditOrganization";
// import ListOrganizations from "./organizations/ListOrganizations";
import CreateListPageLayout from "./page-layout/createListPageLayout";
import CreateLayoutPage from "./page-layout/createPageLayout";
import QuickPageLayout from "./page-layout/quickPageLayout";
import AddPage from "./pages/AddPage";
import EditPage from "./pages/EditPage";
import ListPages from "./pages/ListPages";
import ListPagesByEntityId from "./pages/ListPagesByEntity";
import AddProject from "./projects/AddProject";
import ListProjects from "./projects/ListProjects";
import ProjectDetails from "./projects/ProjectDetails";
import AddSubMenu from "./submenus/AddSubMenu";
import EditSubMenu from "./submenus/EditSubMenu";
import ListSubMenus from "./submenus/ListSubMenus";
import ListTemplates from "./templates/ListTemplates";
import ViewTemplate from "./templates/ViewTemplate";

import Loginform from "./auth/Loginform";
import ListOrganizationN from "./organizations/ListorganizationN";
import ReportForm from "./reports/AddReport";
import ListReports from "./reports/ListReport";
import ListHTMLComponents from "./html-components/ListHTMLComponents";
import HTMLComponentRecordForm from "./html-components/HTMLComponentDetails";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Loginform/>} />
        <Route path="/organizations" element={<ListOrganizationN />} />
        <Route path="/create-organization" element={<AddOrganization />} />
        <Route
          path="/organizations/editorganization/:id"
          element={<EditOrganization />}
        />

        <Route path="/projects/:organization_id" element={<ListProjects />} />
        <Route path="/projects" element={<ListProjects />} />
        <Route path="/project/:id" element={<AddProject />} />
        <Route path="/project-details/:id" element={<ProjectDetails />} />
        <Route path="/create-project" element={<AddProject />} />
        <Route path="/create-project/:id" element={<AddProject />} />

        <Route path="/project/:id/menus" element={<ListMenus />} />
        <Route path="/create-menu/:project_id" element={<AddMenu />} />
        <Route path="/menus/editmenu/:id" element={<EditMenu />} />

        <Route
          path="/project/menus/submenus/:menu_id/"
          element={<ListSubMenus />}
        />
        <Route path="/create-sub-menu/:menu_id" element={<AddSubMenu />} />
        <Route path="/submenus/editsubmenu/:id" element={<EditSubMenu />} />

        <Route path="/project/:id/pages" element={<ListPages />} />
        <Route path="/create-page/:page_id" element={<AddPage />} />
        <Route path="/pages/editpage/:id" element={<EditPage />} />

        <Route path="/entities/:project_id" element={<ListEntities />} />
        <Route path="/entity/" element={<AddEntity />} />
        <Route path="/entity/:entity_id" element={<AddEntity />} />

        <Route
          path="/entity-fields/:entity_id"
          element={<ListEntityFields />}
        />
        <Route
          path="/create-entity-field/:entity_id"
          element={<AddEntityField />}
        />
        <Route
          path="/entity-fields/editentityfield/:id"
          element={<EditEntityField />}
        />

        <Route
          path="/database-setting/:project_id"
          element={<DatabaseSetting />}
        />

        <Route
          path="/organization-members/:organization_id"
          element={<ListOrganizationMembers />}
        />
        <Route
          path="/organization-member/editorganizationmember/:organization_id"
          element={<EditOrganizationMember />}
        />
        <Route
          path="/create-organization-member/:organization_id"
          element={<AddOrganizationMember />}
        />
        <Route
          path="/create-page-layout/:page_id"
          element={<CreateLayoutPage />}
        />
        <Route
          path="/create-list-layout/:page_id"
          element={<CreateListPageLayout />}
        />
        <Route path="/templates" element={<ListTemplates />} />
        <Route
          path="/templates/details/:templateId"
          element={<ViewTemplate />}
        />
        <Route
          path="/pages/entities/:entityId"
          element={<ListPagesByEntityId />}
        />
        <Route
          path="/pages-layout/:entityId/:pageId"
          element={<QuickPageLayout />}
        />

        <Route path="/reports/:id" element={<ListReports />} />
        <Route path="/report/:report_id" element={<ReportForm />} />
        <Route path="/create-report" element={<ReportForm />} />

        <Route path="/html-components" element={<ListHTMLComponents />} />
        <Route path="/html-component/view/:id" element={<HTMLComponentRecordForm />} />

      </Routes>
    </Router>
  );
}

export default App;
