import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
// import "./index.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./listpages.css";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListPagesByEntityId() {
  const { entityId } = useParams();
  const navigate = useNavigate();
  const navigateToCreatePage = () => {
    navigate("/create-page/" + localStorage.getItem("project_id"));
  };
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [isFirstPageNumber, setIsFirstPageNumber] = useState(true);
  const [isLastPageNumber, setIsLastPageNumber] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPagesData();
  }, []);

  // List Pages
  const getPagesData = async (pNumber) => {
    let page = pageNumber;
    if (pNumber >= 0) {
      page = pNumber;
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/page/entity/${entityId}?page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      )
      .then((response) => {
        setPages(response.data.content);
        setTotalPages(response.data.totalPages);
        setIsFirstPageNumber(response.data.first);
        setIsLastPageNumber(response.data.last);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Pages</h2>
          <hr />

          <div className="row">
            <div className="col-md-8">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/project-details/" + localStorage.getItem("project_id")
                      }
                    >
                      Details
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={"/entities/" + localStorage.getItem("project_id")}
                    >
                      Entities
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Pages</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-4" style={{ display: "flex" }}>
              <div>
                <button
                  style={{
                    cursor: "pointer",
                    marginRight: "5px",
                    padding: "5px",
                    borderRadius: "4px",
                  }}
                  onClick={navigateToCreatePage}
                >
                  Add New Page
                </button>
              </div>
            </div>
          </div>

          <hr />

          <table className="table">
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pages &&
                pages.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <Link to={`/pages/editpage/${item.id}`}>
                          <button
                            type="button"
                            style={{
                              cursor: "pointer",
                              marginRight: "5px",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          >
                            Edit
                          </button>
                        </Link>
                        &nbsp;
                        <Link to={`/pages-layout/${entityId}/${item.id}`}>
                          <button
                            type="button"
                            style={{
                              cursor: "pointer",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          >
                            Layout
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {totalPages > 0 && (
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                {isFirstPageNumber ? (
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">
                      Previous
                    </a>
                  </li>
                ) : (
                  <li class="page-item enabled">
                    <a class="page-link" href="#" tabindex="-1">
                      Previous
                    </a>
                  </li>
                )}
                {Array.from({ length: totalPages }, (_, i) => (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={() => {
                        setPageNumber({ i });
                        getPagesData(i);
                      }}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
                {isLastPageNumber ? (
                  <li class="page-item disabled">
                    <a class="page-link" href="#">
                      Next
                    </a>
                  </li>
                ) : (
                  <li class="page-item">
                    <a class="page-link" href="#">
                      Next
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          )}
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default ListPagesByEntityId;
