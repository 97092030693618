import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListMenus() {
  let [loading, setLoading] = useState(false);
  let [menus, setMenus] = useState([]);
  let i = 1;

  const { id } = useParams();
  const navigate = useNavigate();
  const navigateToCreateMenu = () => {
    navigate("/create-menu/" + id);
  };
  const navigateProjectDetails = () => {
    navigate("/project-details/" + id);
  };
  const navigateProjects = () => {
    navigate("/projects");
  };

  useEffect(() => {
    getMenusData();
  }, []);

  // List Projects

  const getMenusData = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/menu/project/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setMenus(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSet = (item) => {
    let setvalue = item;
    localStorage.setItem("menu_id", setvalue);
    navigate(`/project/menus/submenus/${localStorage.getItem("menu_id")}`);
  };

  return (
    /* createdAt, createdBy, id, name*/
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Menus</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/project-details/" + localStorage.getItem("project_id")
                      }
                    >
                      Details
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">Menus</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-3">
              <button
                style={{cursor: "pointer",marginRight:"5px", padding:"5px", borderRadius:"4px"}}
                onClick={navigateToCreateMenu}
              >
                Add New Menu
              </button>
            </div>
          </div>

          <hr />

          <table className="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Created By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {menus.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.createdBy}</td>
                    <td>
                      <Link to={`/menus/editmenu/${item.id}`}>
                        <button
                          type="button"
                        
                          style={{cursor: "pointer",marginRight:"5px", padding:"5px", borderRadius:"4px"}}
                        >
                          Edit
                        </button>
                      </Link>
                      {/* <Link to={`/project/menus/submenus/${item.id}`}>
                                            <button type="button" className="btn btn-info">Submenus</button> 
                                            </Link> */}
                      <button
                        type="button"
                        style={{cursor: "pointer",marginRight:"5px", padding:"5px", borderRadius:"4px"}}
                        onClick={(event) => handleSet(item.id)}
                      >
                        Submenus
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default ListMenus;
