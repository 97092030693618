import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import NavigationBar from '../common/navbar';
import Header from '../common/header';

const HTMLComponentRecordForm = ({ }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        htmlContent: '',
        showEntityField: false,
        showFieldValidator: false,
        showGetDataFrom: false,
        showLabel: false,
        showDefaultValue: false,
        showAllowedNumberOfCharacters: false,
        showUniqueCheck: false,
        showMarkMandatoryField: false,
        showValidationRegex: false,
    });

    useEffect(() => {
        if (id) {
            // Fetch the existing data using the provided id
            axios.get(`${process.env.REACT_APP_API_URL}/html-component/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "*/*",
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("user_info")).accessToken,
                },
            }).then((response) => {
                setFormData(response.data);
            })
                .catch((error) => {
                    console.error('Error', error);
                });
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: fieldValue,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(id) {
            formData.id = id;
        }
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/html-component`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization:
                    "Bearer " +
                    JSON.parse(localStorage.getItem("user_info")).accessToken,
            },
        })
            .then((response) => {
                console.log('Success', response.data);
                navigate("/html-components");

            })
            .catch((error) => {
                console.error('Error', error);
                // Handle the error case appropriately
            });
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <NavigationBar />
                <div className="col-md-9">
                    <Header />
                    <h2>Add HTML Element</h2>
                    <hr />

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description:</label>
                            <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="htmlContent">HTML Content:</label>
                            <textarea
                                className="form-control"
                                id="htmlContent"
                                name="htmlContent"
                                value={formData.htmlContent}
                                onChange={handleChange}
                                required={true}
                            />
                        </div>

                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="showEntityField"
                                name="showEntityField"
                                checked={formData.showEntityField}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="showEntityField">
                                Show entity field
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="showFieldValidator"
                                name="showFieldValidator"
                                checked={formData.showFieldValidator}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="showFieldValidator">
                                Show entity field validator
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="showGetDataFrom"
                                name="showGetDataFrom"
                                checked={formData.showGetDataFrom}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="showGetDataFrom">
                                Show get data from
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="showLabel"
                                name="showLabel"
                                checked={formData.showLabel}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="showLabel">
                                Show label
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="showDefaultValue"
                                name="showDefaultValue"
                                checked={formData.showDefaultValue}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="showDefaultValue">
                                Show default Value
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="showAllowedNumberOfCharacters"
                                name="showAllowedNumberOfCharacters"
                                checked={formData.showAllowedNumberOfCharacters}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="showAllowedNumberOfCharacters">
                                Show allowed number of Characters
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="showUniqueCheck"
                                name="showUniqueCheck"
                                checked={formData.showUniqueCheck}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="showUniqueCheck">
                                Show unique check
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="showMarkMandatoryField"
                                name="showMarkMandatoryField"
                                checked={formData.showMarkMandatoryField}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="showMarkMandatoryField">
                                Show mandatory field check
                            </label>
                        </div>

                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="showValidationRegex"
                                name="showValidationRegex"
                                checked={formData.showValidationRegex}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="showValidationRegex">
                                Show validation regular expression
                            </label>
                        </div>

                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>

    );
};

export default HTMLComponentRecordForm;
