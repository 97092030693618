import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
import "../btn/btn.css"
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function AddEntity() {
  const { entity_id } = useParams();
  const navigate = useNavigate();

  const navigateToEntitys = () => {
    navigate("/entities/" + localStorage.getItem("project_id"));
  };

  let [loading, setLoading] = useState(true);
  let [entity, setEntity] = useState({ name: "", description: "" });
  let [entityErrors, setEntityErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEntity({ ...entity, [name]: value });
  };

  useEffect(() => {
    if (entity_id) {
      getProjectEntityDetails();
    }
  }, [entityErrors]);

  // Get Project Entity Details
  const getProjectEntityDetails = async () => {
    
    await axios
      .get(`${process.env.REACT_APP_API_URL}/entity/${entity_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setEntity({
          name: response.data.name,
          description: response.data.description,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Add Project Entity Details
  const addProjectEntity = async () => {
   
    var mainbody = {
      name: entity.name,
      description: entity.description,
      projectId: localStorage.getItem("project_id"),
      createdBy: JSON.parse(localStorage.getItem("user_info")).id,
    };
    if (entity_id) {
      mainbody["id"] = entity_id;
    }
    let result = await fetch(`${process.env.REACT_APP_API_URL}/entity/`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      body:JSON.stringify(mainbody),
    }
  );
  if (result.status==200) {   
    navigate("/entities/" + localStorage.getItem("project_id"))
  } 
  else if(result.status==400){
    alert("Bad request")
    }
  else{
    localStorage.clear("user_info");
    navigate("/");
  }
  
  };


  
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = " * Name is Required";
    }
    if (!values.description) {
      errors.description = " * Description is Required";
    }
    return errors;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Entity</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={"/entities/" + localStorage.getItem("project_id")}
                    >
                      Entities
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Create New Entity</li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />
          <form role="form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={entity.name}
                    onChange={handleChange}
                  />
                </div>
                <p style={{ color: "red" }}>{entityErrors.name}</p>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    aria-rowspan={3}
                    name="description"
                    value={entity.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <p style={{ color: "red" }}>{entityErrors.description}</p>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                <button
                  type="button"
                  style={{padding:"5px",marginRight:"4px",borderRadius:"4px"}}
                  onClick={() => addProjectEntity()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  style={{padding:"5px",marginRight:"4px",borderRadius:"4px"}}
                  onClick={navigateToEntitys}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddEntity;
