import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
import "../btn/btn.css"

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function AddEntityField() {
  const { entity_id } = useParams();
  let [loading, setLoading] = useState(true);
  let [entity_field, setEntityField] = useState({
    name: "",
    description: "",
    entityFieldType: "",
    defaultValue: "",
  });
  let [entityFieldErrors, setEntityFieldErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();
  const navigateToEntitys = () => {
    // navigate('/project/' + entity_id + '/entity_fields');
    navigate("/entity-fields/" + localStorage.getItem("entity_id"));
  };

  const handleChange = (name) => (event) => {
    const { name, value } = event.target;
    setEntityField({ ...entity_field, [name]: value });
  };

  // Add Project Entity Details
  // const addEntityField = async () => {
  //     setLoading(true);
  //         var mainbody= {
  //             "name": entity_field.name,
  //             "description": entity_field.description,
  //             "entityId": localStorage.getItem("entity_id"),
  //             "entityFieldType": entity_field.entityFieldType,
  //             "defaultValue": entity_field.defaultValue
  //         };

  //     var config = {
  //         method: 'post',
  //         url: `${process.env.REACT_APP_API_URL}/entity-field/`,
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'Accept': '*/*'
  //         },
  //         data:mainbody
  //     };
  //     axios(config);
  //     navigateToEntitys();
  // }

  const handleSubmit = (e) => {
    e.preventDefault();

    setEntityFieldErrors(validate(entity_field));
    setIsSubmit(true);

    // navigateToEntitys();
  };

  useEffect(() => {
    if (Object.keys(entityFieldErrors).length === 0 && isSubmit) {
      if (isSubmit) {
       
        onSubmitData()
      }
    }
  }, [entityFieldErrors]);


  const onSubmitData = async () => {
   
    let result = await fetch(`${process.env.REACT_APP_API_URL}/entity-field/`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
        body:JSON.stringify({
          name: entity_field.name,
          description: entity_field.description,
          entityId: localStorage.getItem("entity_id"),
          entityFieldType: entity_field.entityFieldType,
          defaultValue: entity_field.defaultValue,
        }),
      }
    );
    
    if (result.status==200) {   
      navigate("/entity-fields/" + localStorage.getItem("entity_id"))
    } 
    else if(result.status==400){
      alert("Bad request")
      }
    else{
      localStorage.clear("user_info");
      navigate("/");
    }
 
};

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = " * Name is Required";
    }
    if (!values.description) {
      errors.description = " * Description is Required";
    }

    if (!values.entityFieldType) {
      errors.entityFieldType = " * entity Field Type is Required";
    }

    if (!values.defaultValue) {
      errors.defaultValue = " * Default Value is Required";
    }

    return errors;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Entity Field</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={"/entities/" + localStorage.getItem("project_id")}
                    >
                      Entities
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={"/entity-fields/" + localStorage.getItem("entity_id")}
                    >
                      Entity Fields
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create New Entity Field
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />

          <form role="form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={entity_field.name}
                    name="name"
                    onChange={handleChange("name")}
                    onBlur={handleChange("name")}
                  />
                </div>
                <p style={{ color: "red" }}>{entityFieldErrors.name}</p>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    aria-rowspan={3}
                    value={entity_field.description}
                    name="description"
                    onChange={handleChange("description")}
                    onBlur={handleChange("description")}
                  ></textarea>
                </div>
                <p style={{ color: "red" }}>{entityFieldErrors.description}</p>
                <div className="form-group">
                  <label>Entity Field Type</label>
                  <input
                    type="text"
                    className="form-control"
                    id="entityFieldType"
                    name="entityFieldType"
                    value={entity_field.entityFieldType}
                    onChange={handleChange("entityFieldType")}
                    onBlur={handleChange("entityFieldType")}
                  />
                </div>
                <p style={{ color: "red" }}>
                  {entityFieldErrors.entityFieldType}
                </p>
                <div className="form-group">
                  <label>Default Value</label>
                  <input
                    type="text"
                    className="form-control"
                    id="defaultValue"
                    name="defaultValue"
                    value={entity_field.defaultValue}
                    onChange={handleChange("defaultValue")}
                    onBlur={handleChange("defaultValue")}
                  />
                </div>
                <p style={{ color: "red" }}>{entityFieldErrors.defaultValue}</p>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                <button type="submit" style={{cursor: "pointer",marginRight:"5px", padding:"5px", borderRadius:"4px"}}>
                  Submit
                </button>
                <button
                  type="submit"
                  style={{cursor: "pointer", padding:"5px", borderRadius:"4px"}}
                  onClick={navigateToEntitys}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddEntityField;
