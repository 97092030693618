import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
import "../btn/btn.css"

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ProjectDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateToMenuList = () => {
    navigate("/project/" + id + "/menus");
  };

  const navigateToPageList = () => {
    navigate("/project/" + id + "/pages");
  };

  const navigateToEntitiesList = () => {
    navigate("/entities/" + id);
  };

  const navigateToDatabaseSettings = () => {
    navigate("/database-setting/" + id);
  };

  const navigateToReportsList = () => {
    navigate("/reports/" + id);
  };

  let [loading, setLoading] = useState(false);
  let [project, setProject] = useState({ name: "", description: "" });

  useEffect(() => {
    if (id) {
      localStorage.setItem("project_id", id);
      getProjectsDetails(id);
    }
    setProject({ name: "", description: "" });
    setLoading(false);
  }, []);

  // Get Project Details
  const getProjectsDetails = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/project/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setProject({
          name: response.data.name,
          description: response.data.description,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <div className="col-md-12">
            <div className="row">
              <h1>{project.name}</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/organizations">Organizations</Link>
                </li>

                <li className="breadcrumb-item active">
                  <Link
                    to={"/projects/" + localStorage.getItem("organization_id")}
                  >
                    Projects
                  </Link>
                </li>

                <li className="breadcrumb-item active">Details</li>
              </ol>
            </div>

            <hr />
            <dl>
              <dt>Description</dt>
              <dd>{project.description}</dd>
            </dl>
          </div>
          <hr />

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-9">
                <h4>Database Details</h4>
              </div>
              <div className="col-md-3">
                <button
                   style={{padding:"5px",borderRadius:"4px" }}  
                  onClick={navigateToDatabaseSettings}
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
          <hr />

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-9">
                <h4>Entities</h4>
              </div>
              <div className="col-md-3">
                <button
                  style={{padding:"5px",borderRadius:"4px" }}
                  onClick={navigateToEntitiesList}
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
          <hr />

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-9">
                <h4>Menus</h4>
              </div>
              <div className="col-md-3">
                <button
                   style={{padding:"5px",borderRadius:"4px" }}
                  onClick={navigateToMenuList}
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
          <hr />

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-9">
                <h4>Pages</h4>
              </div>
              <div className="col-md-3">
                <button
                 style={{padding:"5px",borderRadius:"4px" }}
                  onClick={navigateToPageList}
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
          <hr />

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-9">
                <h4>Reports</h4>
              </div>
              <div className="col-md-3">
                <button
                 style={{padding:"5px",borderRadius:"4px" }}
                  onClick={navigateToReportsList}
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
          <hr />

          {/* <div className="col-md-12">
                        <div className='row'>
                            <div className="col-md-9">
                                <h4>
                                    Auth Settings
                                </h4>
                            </div>
                            <div className="col-md-3">
                                <button className='btn btn-info float-right'>View Details</button>
                            </div>
                        </div>
                    </div><hr /> */}
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default ProjectDetails;
