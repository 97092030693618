import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const ReportForm = () => {
  let [loading, setLoading] = useState(false);
  const { report_id } = useParams();
  const [report, setReport] = useState({
    name: '',
    description: '',
    projectId: localStorage.getItem("project_id"),
    reportProperties: {
      name: '',
      pageWidth: '',
      pageHeight: '',
      leftMargin: '',
      rightMargin: '',
      topMargin: '',
      bottomMargin: '',
      columnWidth: '',
      reportQuery: '',
      reportFields: [],
    },
  });

  useEffect(() => {
    if (report_id) {
      getReportDetails(report_id);
    }
    setLoading(false);
  }, []);

    // Get Report Details
    const getReportDetails = async () => {
      setLoading(true);
      await axios
        .get(`${process.env.REACT_APP_API_URL}/report/${report_id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        })
        .then((response) => {
          setReport(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReport((prevReport) => ({
      ...prevReport,
      [name]: value,
    }));
  };

  const handleReportPropertiesChange = (e) => {
    const { name, value } = e.target;
    setReport((prevReport) => ({
      ...prevReport,
      reportProperties: {
        ...prevReport.reportProperties,
        [name]: value,
      },
    }));
  };

  const handleReportFieldChange = (index, e) => {
    const { name, value } = e.target;
    setReport((prevReport) => {
      const { reportProperties } = prevReport;
      const { reportFields } = reportProperties;
      reportFields[index][name] = value;
      return {
        ...prevReport,
        reportProperties: {
          ...reportProperties,
          reportFields: [...reportFields],
        },
      };
    });
  };

  const handleAddReportField = () => {
    setReport((prevReport) => {
      const { reportProperties } = prevReport;
      const { reportFields } = reportProperties;
      reportFields.push({ name: '', value: '' });
      return {
        ...prevReport,
        reportProperties: {
          ...reportProperties,
          reportFields: [...reportFields],
        },
      };
    });
  };

  const handleRemoveReportField = (index) => {
    setReport((prevReport) => {
      const { reportProperties } = prevReport;
      const { reportFields } = reportProperties;
      reportFields.splice(index, 1);
      return {
        ...prevReport,
        reportProperties: {
          ...reportProperties,
          reportFields: [...reportFields],
        },
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await fetch(`${process.env.REACT_APP_API_URL}/report`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
        body: JSON.stringify(report),
      }
    );

    console.log(report); // You can perform further actions here, such as sending the report data to the server
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Add/Edit Report</h2>
          <hr />

          <div className="row">
            <div className="col-md-8">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/reports/" + localStorage.getItem("project_id")
                      }
                    >
                      Reports
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Add New Report</li>
                </ol>
              </nav>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="col-md-6 mb-3">
              <label className="form-label">Name:</label>
              <input type="text" className="form-control" name="name" value={report.name} onChange={handleChange} required />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Description:</label>
              <textarea className="form-control" name="description" value={report.description} onChange={handleChange} required />
            </div>
            <h3>Report Properties:</h3>
            <div className="col-md-4 mb-3">
              <label className="form-label">Report Name:</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={report.reportProperties.name}
                onChange={handleReportPropertiesChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Query:</label>
              <textarea className="form-control" name="description" value={report.reportQuery} onChange={handleChange} required />
            </div>
            <div className="row g-3">
              <div className="col">
                <label className="form-label">Page Width:</label>
                <input
                  type="text"
                  className="form-control"
                  name="pageWidth"
                  value={report.reportProperties.pageWidth}
                  onChange={handleReportPropertiesChange}
                  required
                />
              </div>
              <div className="col">
                <label className="form-label">Page Height:</label>
                <input
                  type="text"
                  className="form-control"
                  name="pageHeight"
                  value={report.reportProperties.pageHeight}
                  onChange={handleReportPropertiesChange}
                  required
                />
              </div>
              <div className="col">
                <label className="form-label">Left Margin:</label>
                <input
                  type="text"
                  className="form-control"
                  name="leftMargin"
                  value={report.reportProperties.leftMargin}
                  onChange={handleReportPropertiesChange}
                  required
                />
              </div>
              <div className="col">
                <label className="form-label">Right Margin:</label>
                <input
                  type="text"
                  className="form-control"
                  name="rightMargin"
                  value={report.reportProperties.rightMargin}
                  onChange={handleReportPropertiesChange}
                  required
                />
              </div>
              <div className="col">
                <label className="form-label">Top Margin:</label>
                <input
                  type="text"
                  className="form-control"
                  name="topMargin"
                  value={report.reportProperties.topMargin}
                  onChange={handleReportPropertiesChange}
                  required
                />
              </div>
              <div className="col">
                <label className="form-label">Bottom Margin:</label>
                <input
                  type="text"
                  className="form-control"
                  name="bottomMargin"
                  value={report.reportProperties.bottomMargin}
                  onChange={handleReportPropertiesChange}
                  required
                />
              </div>
              <div className="col">
                <label className="form-label">Column Width:</label>
                <input
                  type="text"
                  className="form-control"
                  name="columnWidth"
                  value={report.reportProperties.columnWidth}
                  onChange={handleReportPropertiesChange}
                  required
                />
              </div>
            </div>
            <h4>Report Fields:</h4>
            {report.reportProperties.reportFields.map((field, index) => (
              <div className="row g-3 mb-1" key={index}>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={field.name}
                    onChange={(e) => handleReportFieldChange(index, e)}
                    placeholder="Field Name"
                    required
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="value"
                    value={field.value}
                    onChange={(e) => handleReportFieldChange(index, e)}
                    placeholder="Field Value"
                    required
                  />
                </div>
                <div className="col-auto">
                  <button type="button" className="btn btn-danger" onClick={() => handleRemoveReportField(index)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <div className="text-end mt-3">
              <button type="button" className="btn btn-primary" onClick={handleAddReportField}>
                Add Field
              </button>
            </div>
            <div className="text-end mt-3">
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReportForm;
