import axios from "axios";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Element from "./Element";
import "./pagelayout.css";

import * as Icon from "react-bootstrap-icons";
import SelectionElementConfigurationComponent from "./selectionElementConfiguration";

function CreatePageLayout() {
  const { page_id } = useParams();
  // To keep track of selected elements
  const [lastClickedElementId, setLastClickedElementId] = useState("");
  const [clickedElementId, setClickedElementId] = useState("");
  // HTML Components data and map of html components based on ID in order to read properties quickly
  const [htmlComponents, setHtmlComponents] = useState([]);
  const [htmlComponentsAsMap, setHtmlComponentsAsMap] = useState({});
  // List of regular expressions stored in database
  const [buttonactions, setButtonactions] = useState([{'name':'Generate report'},{'name':'Save'},{'name':'API Call'}]);
  const [validatonregex, setValidatonRegex] = useState([]);
  const [entityfields, setEntityFields] = useState([]);
  const [pageDetails, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonApiUrl, setbuttonApiUrl] = useState(false);
  const [errorMessageForButtonApiUrl, seterrorMessageForButtonApiUrl] = useState(false);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [pageLayout, setPageLayout] = useState([]);
  const [showHideSettings, setShowHideSettings] = useState({
    showEntityField: false,
    showFieldValidator: false,
    showLabel: false,
    showPlaceHolder: false,
    showNumberOfRows: false,
    showNumberOfColumns: false,
    showAllowedNumberOfCharacters: false,
    showDefaultValue: false,
    showUniqueCheck: false,
    showMarkMandatoryField: false,
    showValidationRegex: false,
    showGetDataFrom: false
  });
  let [pageField, setPageField] = useState({
    isMandatoryField: "",
    rowNumber: "",
    columnNumber: "",
    fieldLabel: "",
    mappedEntityFieldId: "",
    fieldValidatorId: "",
    numberOfCharacterAllowed: "",
    checkUniqueness: "",
    isMandatoryField: "",
    providedRegex: "",
    fieldActions: [],
    htmlComponentId: "",
    pageId: "",
    defaultValues: "",
    buttonAction: "",
    configuration: "",
    apiUrl:"",
    httpMethod:"",
    payload:""
  });
  const [selectionControlData, setSelectionControlData] = useState({
    setFieldDataFrom: "",
    entityType: "", nameField: "", valueField: "", customerValues: { id: "", name: "" }
  });

  const [popup, setPopup] = useState(false);
  const [notes, setNotes] = useState([]);
  const [title, setTitle] = useState("");
  const [toggleSubmit, setToggleSubmit] = useState(true);
  const [isEdit, setIsEdit] = useState(null);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    if (id === "htmlComponentId") {
      setShowHideSettings(htmlComponentsAsMap[value]);
    }
    if (value === "API Call") {
      setbuttonApiUrl(true);
    }else{
      setbuttonApiUrl(false);
    }
    const fieldValue = type === 'checkbox' ? checked : value;
    setPageField((prevData) => ({
      ...prevData,
      [id]: fieldValue,
    }));
  };

  const handleSelectionControlChange = (e) => {
    const { id, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setSelectionControlData((prevData) => ({
      ...prevData,
      [id]: fieldValue,
    }));
  };

  function handleAdd(e) {
    e.preventDefault();
    if (!title) {
      alert("plz fill the data");
    } else if (title && !toggleSubmit) {
      setNotes(
        notes.map((elem) => {
          if (elem.id == isEdit) {
            return { ...elem, name: title };
          }
          return elem;
        })
      );

      setToggleSubmit(true);
      setTitle("");
      setIsEdit(null);
    } else {
      const allInputData = { id: title, name: title, value: title };
      setNotes([...notes, allInputData]);
      setTitle(" ");
    }
  }

  const deleteItem = (index) => {
    const updatedItems = notes.filter((elem) => {
      return index != elem.id;
    });
    setNotes(updatedItems);
  };

  const editItem = (id) => {
    let newEditItem = notes.find((elem) => {
      return elem.id == id;
    });

    setToggleSubmit(false);
    setTitle(newEditItem.name);
    setIsEdit(id);
  };

  const handleDelete = (e) => {
    let elementId = e.target.parentNode.id;
    let fieldId = e.target.parentNode.getAttribute("name");
    debugger;
    if (elementId && elementId !== '') {
      // Define the URL for your delete API
      const deleteApiUrl =  process.env.REACT_APP_API_URL+'/page-field/' + fieldId; // Replace with your API URL
      const headers={
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      };
      // Make the DELETE request using Axios
      axios.delete(deleteApiUrl,headers)
        .then(response => {
          // Handle success response
          console.log('Delete request successful:', response.data);
          document.getElementById(elementId).remove();
          toast.success("Field deleted sucessfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          // Optionally, you can perform additional actions after successful deletion
        })
        .catch(error => {
          // Handle error response
          console.error('Delete request failed:', error);
          // Optionally, you can display an error message or take other actions on failure
        });
    }
  
  };

  const handleClick = (e, elementId) => {
    
    const { id, value, type, checked } = e.target;
    e.preventDefault();
    if (lastClickedElementId != "" && lastClickedElementId != elementId) {
      document.getElementById(lastClickedElementId).style.backgroundColor = "";
    }
    if (document.getElementById(clickedElementId)) {
      document.getElementById(clickedElementId).style.backgroundColor = "";
    }
    if (elementId && elementId != "") {
      document.getElementById(elementId).style.backgroundColor = "#FADA9D";
      setClickedElementId(elementId);
      setLastClickedElementId(elementId);
      if (elementId) {
        let clickedElementData = elementId.split("_");
        let rowNumber = 0;
        let columnNumber = 0;
        if (clickedElementData && clickedElementData.length == 4) {
          rowNumber = clickedElementData[1];
          columnNumber = clickedElementData[3];
        }

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/page-field/page/${page_id}/${rowNumber}/${columnNumber}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization:
                  "Bearer " +
                  JSON.parse(localStorage.getItem("user_info")).accessToken,
              },
            }
          )
          .then((response) => {
            if (response.data != "") {
              setPageField(response.data);
              try {
                setSelectionControlData(JSON.parse(response.data.configuration));
              } catch (e) {
              }
              setShowHideSettings(htmlComponentsAsMap[response.data.htmlComponentId])
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
  };

  const addNewRow = () => {
    let columns = prompt("Please enter number of columns you want", "1");
    let row = document.createElement("div");
    row.id = "r_" + totalNumberOfRows;
    row.className = "row";
    if (columns > 12) {
      alert("Cannot add coulumns more than 12, minimum value should be 1 and maximum 12");
    } else {
      for (let index = 0; index < columns; index++) {
        
        let column = document.createElement("div");
        column.id = "r_" + totalNumberOfRows + "_c_" + index;
        column.className = "col";

        column.style["border"] = "1px dotted blue";
        column.style["height"] = "80px";
        column.addEventListener("click", function (e) {
          handleClick(e, "r_" + totalNumberOfRows + "_c_" + index);
        });
        row.appendChild(column);
      }
      setTotalNumberOfRows(totalNumberOfRows + 1);
      const target = document.querySelector("#addRow");
      target.parentNode.insertBefore(row, target);
    }
  };

  useEffect(() => {
    setLoading(true);
    getElementData();
    getPageDetails();
    getValidationRegex();
    setLoading(false);
  }, [totalNumberOfRows]);

  const getElementData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/html-component`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setHtmlComponents(response.data);
        let htmlElementMap = {};
        response.data.map((htmlElement, i) => {
          htmlElementMap[htmlElement.name] = htmlElement;
        });
        setHtmlComponentsAsMap(htmlElementMap);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getValidationRegex = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/validation-regex`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setValidatonRegex(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getEntityFields = async (entityId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/entity-field/entity/${entityId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setEntityFields(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getPageDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/page/${page_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setPageDetails(response.data);
        getEntityFields(response.data["mappedEntityId"]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };


  //Add Page fields
  const addPageFields = (e) => {
    e.preventDefault();
    let clickedElementData = clickedElementId.split("_");
    let rowNumber = 0;
    let columnNumber = 0;
    if (clickedElementData && clickedElementData.length == 4) {
      rowNumber = parseInt(clickedElementData[1]);
      columnNumber = parseInt(clickedElementData[3]);
    }
    debugger
    if(buttonApiUrl && (pageField.apiUrl=="" || pageField.apiUrl==null)){
      seterrorMessageForButtonApiUrl(true)
      return;
    }else{
      seterrorMessageForButtonApiUrl(false)
    }
    let mainbody = {
      rowNumber: rowNumber,
      columnNumber: columnNumber,
      fieldLabel: pageField.fieldLabel,
      mappedEntityFieldId: pageField.mappedEntityFieldId,
      fieldValidatorId: pageField.fieldValidatorId,
      numberOfCharacterAllowed: pageField.numberOfCharacterAllowed,
      checkUniqueness: pageField.checkUniqueness,
      isMandatoryField: pageField.isMandatoryField,
      providedRegex: pageField.providedRegex,
      fieldActions: pageField.fieldActions,
      htmlComponentId: pageField.htmlComponentId,
      pageId: page_id,
      buttonAction: pageField.buttonAction,
      apiUrl: pageField.apiUrl,
      httpMethod:pageField.httpMethod,
      payload:pageField.payload,
      configuration: JSON.stringify(selectionControlData),
      defaultValues: pageField.defaultValues
    };
    
    if (pageField.id) {
      mainbody["id"] = pageField.id;
    }
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/page-field`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      data: mainbody,
    };
    axios(config);
    toast.success("Added sucessfully", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  useEffect(() => {
    setLoading(true);
    getPageLayout();
  }, []);

  //getting page layout
  const getPageLayout = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/page-field/page/${page_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setPageLayout(response.data);
        setTotalNumberOfRows(response.data.length);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const selectBoxValues = [
    {
      name: "Custom values",
    },
    {
      name: "Existing entity",
    },
    {
      name: "API",
    },
  ];



  const handleModal = () => {
    setPopup(true);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
          <NavigationBar />
          <div className="col-md-9 ">
            <h3>{pageDetails.name}</h3>
            <hr />

            <div className="row">
              <div className="col-md-9">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        to={
                          "/project/" +
                          localStorage.getItem("project_id") +
                          "/pages"
                        }
                      >
                        Pages
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">Create New Page</li>
                  </ol>
                </nav>
              </div>
            </div>
            <hr />
            <div className="row">
              <div
                id="main-content"
                className="col-md-9"
                style={{ border: "0.5px solid black", width: "73%" }}
              >
                <div id="main-inner-content" className="col-md-12">
                  {pageLayout
                    ? pageLayout.map((field, i) => (
                      <div
                        id={"r_" + i}
                        className="row"
                        style={{ width: "100%" }}
                        key={i}
                      >
                        {field.rowData
                          ? field.rowData.map((field_j, j) => (
                            <div
                              name={field_j.id}
                              key={j}
                              id={"r_" + i + "_c_" + j}
                              className={"col-md-" + field.columnSize + " mb-2"}
                              style={{
                                border: "1px dotted blue",
                                height: "auto"
                              }}
                              onClick={(e) => handleClick(e, "r_" + i + "_c_" + j)}
                            >
                              <Icon.XSquare
                                style={{ float: "right" }}
                                onClick={(e) => handleDelete(e)}
                              ></Icon.XSquare>
                              <Icon.PencilSquare
                                style={{
                                  float: "right",
                                  marginRight: "5px",
                                }}
                                onClick={(e) => handleClick(e, false)}
                              ></Icon.PencilSquare>
                              <Element field={field_j} />
                            </div>
                          ))
                          : null}
                      </div>
                    ))
                    : null}
                </div>
                <div style={{ marginBottom: "50px" }} id="addRow">
                  <Button onClick={addNewRow}>Add Row</Button>
                </div>
                {pageLayout
                  ? pageLayout.map((field, i) => (
                    <div key={i}>
                      <Element field={field} />
                      <p></p>
                    </div>
                  ))
                  : null}
              </div>

              <div
                className="col-md-3"
                style={{
                  border: "1px solid black",
                  marginLeft: "10px",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                }}
              >
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-md-12">
                    <form role="form" onSubmit={(e) => addPageFields(e)}>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Selected Element:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {clickedElementId}
                          </span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Mapped Entity:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {clickedElementId}
                          </span>
                        </label>
                      </div>
                      <div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            HTML component{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <div className="form-group">
                          <select
                            className="form-select"
                            id="htmlComponentId"
                            value={pageField.htmlComponentId}
                            label="HTML Component"
                            onChange={handleChange}
                            style={{ width: "100%" }}
                          >
                            <option>Select HTML Component</option>
                            {htmlComponents.map((data, index) => {
                              return (
                                <option key={index} value={data.name}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      {showHideSettings.showEntityField != null && showHideSettings.showEntityField != false && (
                        <div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Entity Fields{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <div className="form-group">
                            <select
                              className="form-select"
                              id="mappedEntityFieldId"
                              value={pageField.mappedEntityFieldId}
                              label="Entity Fields"
                              onChange={handleChange}
                              style={{ width: "100%" }}
                            >
                              <option>Select Entity Fields</option>
                              {entityfields.map((data, index) => {
                                return (
                                  <option key={index} value={data.name}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      )}

                      {showHideSettings.showLabel != null && showHideSettings.showLabel != false && (
                        <div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Label <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="fieldLabel"
                              value={pageField.fieldLabel}
                              label="Label"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      )}

                      {showHideSettings.showGetDataFrom != null && showHideSettings.showGetDataFrom != false && (
                        <SelectionElementConfigurationComponent selectionControlData={selectionControlData} handleSelectionControlChange={handleSelectionControlChange} setSelectionControlData={setSelectionControlData} />
                      )}

                      {showHideSettings.showAllowedNumberOfCharacters != null && showHideSettings.showAllowedNumberOfCharacters !=
                        false && (
                          <div>
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">
                                Allowed number of characters
                              </label>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="numberOfCharacterAllowed"
                                value={
                                  pageField.numberOfCharacterAllowed
                                }
                                label="Allowed Character"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}

                      {showHideSettings.showUniqueCheck != null && showHideSettings.showUniqueCheck != false && (
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              id="checkUniqueness"
                              value={pageField.checkUniqueness}
                              checked={pageField.checkUniqueness == true}
                              onChange={handleChange}
                            />{" "}
                            Check Uniqeness
                          </label>
                        </div>
                      )}

                      {showHideSettings.showMarkMandatoryField != null && showHideSettings.showMarkMandatoryField != false && (
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              id="isMandatoryField"
                              value={pageField.isMandatoryField}
                              checked={pageField.isMandatoryField == true}
                              onChange={handleChange}
                            />{" "}
                            Is mandatory field?
                          </label>
                        </div>
                      )}
                      {showHideSettings.showValidationRegex != null && showHideSettings.showValidationRegex !=
                        false && (
                          <div>
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">
                                Custom Regular Expression
                              </label>
                            </div>
                            <div className="form-group">
                              <select
                                className="form-select"
                                id="providedRegex"
                                value={pageField.providedRegex}
                                label="Regex"
                                onChange={handleChange}
                                style={{ width: "100%" }}
                              >
                                <option>Select Validation Regex</option>
                                {validatonregex.map((data) => {
                                  return (
                                    <option value={data.validationExpression}>
                                      {data.validationExpression}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}

                      {pageField.htmlComponentId == "Button" !=
                        false && (
                          <div>
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">
                                Button Action
                              </label>
                            </div>
                            <div className="form-group">
                                <select
                                className="form-select"
                                id="buttonAction"
                                value={pageField.buttonAction}
                                label="Button Action"
                                onChange={handleChange}
                                style={{ width: "100%" }}
                              >
                                <option>Select Action</option>
                                {buttonactions.map((data) => {
                                  return (
                                    <option value={data.name}>
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                        {buttonApiUrl !=
                        false && (
                          <div>
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">
                               API URL
                              </label>
                            </div>
                            <div className="form-group">
                              {<input
                                type="text"
                                className="form-control"
                                id="apiUrl"
                                value={pageField.apiUrl}
                                label="API URL"
                                onChange={handleChange}
                              />
                               }
                                {errorMessageForButtonApiUrl && (
                                <div className="error-message" style={{"color":"red"}}>
                                  Enter API URL to continue
                                </div>
                              )}
                            
                            </div>
                          </div>
                        )}
                        {buttonApiUrl !=
                        false && (
                          <div>
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">
                               HTTP Method
                              </label>
                            </div>
                            <div className="form-group">
                              {<input
                                type="text"
                                className="form-control"
                                id="httpMethod"
                                value={pageField.httpMethod}
                                label="HTTP Method"
                                onChange={handleChange}
                              />
                               }
                                {errorMessageForButtonApiUrl && (
                                <div className="error-message" style={{"color":"red"}}>
                                  Enter API URL to continue
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      <button
                        type="submit"
                        style={{
                          padding: "5px",
                          marginTop: "5%",
                          borderRadius: "4px",
                        }}
                      >
                        Add Element
                      </button>
                    </form>
                  </div>
                  {/* </div> */}
                  <Dialog
                    open={popup}
                    onClose={() => setPopup(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{ sx: { width: "70%", height: "50%" } }}
                  >
                    <DialogTitle id="alert-dialog-title">
                      Add custom values
                    </DialogTitle>
                    <hr />
                    <DialogContent>
                      {/* <DialogContentText id="alert-dialog-description"> */}
                      <Box sx={{ minWidth: 120 }}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Sr no.</th>
                              <th>Custom value</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {notes.map((elem, index) => {
                              return (
                                <tr key={elem.id}>
                                  <td>{index + 1}</td>
                                  <td>{elem.name}</td>
                                  <td>
                                    <Tooltip
                                      title="Delete"
                                      placement="top-start"
                                    >
                                      <DeleteIcon
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => deleteItem(elem.id)}
                                      />
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title="Edit" placement="top-end">
                                      <EditIcon
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => editItem(elem.id)}
                                      />
                                    </Tooltip>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <hr />
                        <FormControl fullWidth>
                          <div>
                            <TextField
                              id="outlined-basic"
                              label="Add value..."
                              variant="outlined"
                              style={{ width: 300 }}
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                            <span>
                              {toggleSubmit ? (
                                <Tooltip title="Add" placement="top-start">
                                  <AddIcon
                                    style={{
                                      color: "teal",
                                      width: "5%",
                                      marginLeft: 10,
                                      cursor: "pointer",
                                    }}
                                    onClick={handleAdd}
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Update" placement="top-end">
                                  <EditIcon
                                    style={{
                                      color: "blue",
                                      width: "5%",
                                      marginLeft: 10,
                                    }}
                                    onClick={handleAdd}
                                  />
                                </Tooltip>
                              )}
                            </span>
                          </div>
                        </FormControl>
                        <hr />
                      </Box>
                      {/* </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        sx={{ color: "#4C2C7D" }}
                        onClick={() => setPopup(false)}
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() => setPopup(false)}
                        autoFocus
                        sx={{ color: "#4C2C7D" }}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <ToastContainer autoClose={2000} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePageLayout;
