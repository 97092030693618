import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
import "../btn/btn.css"
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
function DatabaseSetting() {
  const { project_id } = useParams();
  const navigate = useNavigate();
  let [loading, setLoading] = useState(true);
  let [databasesetting, setDatabaseSetting] = useState({
    host: "",
    port: "",
    userName: "",
    password: "",
    databaseName: "",
    projectId: "",
    id: "",
  });
  let [databasesettingErrors, setDabaseSettingErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDatabaseSetting({ ...databasesetting, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDabaseSettingErrors(validate(databasesetting));
    setIsSubmit(true);
  };

  useEffect(() => {
    getDataBaseSettingsData();
    setDatabaseSetting({
      host: "",
      port: "",
      userName: "",
      password: "",
      databaseName: "",
      projectId: "",
      id: "",
    });
  }, []);

  const getDataBaseSettingsData = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/database-settings/project/${project_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      )
      .then((response) => {
        setDatabaseSetting({
          host: response.data.host,
          port: response.data.port,
          databaseName: response.data.databaseName,
          id: response.data.id,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(databasesettingErrors).length === 0 && isSubmit) {
      if (isSubmit) {
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/database-settings`,
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
          data: {
            host: databasesetting.host,
            port: databasesetting.port,
            userName: databasesetting.userName,
            password: databasesetting.password,
            projectId: localStorage.getItem("project_id"),
            databaseName: databasesetting.databaseName,
          },
        };
        axios(config);
        return alert("database settings added");
      }
    }
  }, [databasesettingErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.host) {
      errors.host = " * Host is Required";
    }
    if (!values.port) {
      errors.port = " * Port is Required";
    }

    if (!values.databaseName) {
      errors.databaseName = " * Database Name is Required";
    }

    return errors;
  };

  const UpdateDatabaseSettings = async (id, e) => {
    e.preventDefault();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/database-settings`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      data: {
        id: databasesetting.id,
        host: databasesetting.host,
        port: databasesetting.port,
        userName: databasesetting.userName,
        password: databasesetting.password,
        projectId: localStorage.getItem("project_id"),
        databaseName: databasesetting.databaseName,
      },
    };
    axios(config);
    alert("data updated");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Database Setting</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/project-details/" + localStorage.getItem("project_id")
                      }
                    >
                      Details
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Database Setting</li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />
          <form role="form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Host</label>
                  <input
                    type="text"
                    className="form-control"
                    id="host"
                    name="host"
                    value={databasesetting.host}
                    onChange={handleChange}
                  />
                </div>
                <p style={{ color: "red" }}>{databasesettingErrors.host}</p>
                <div className="form-group">
                  <label>Port</label>
                  <input
                    type="text"
                    className="form-control"
                    id="port"
                    aria-rowspan={3}
                    name="port"
                    value={databasesetting.port}
                    onChange={handleChange}
                  />
                </div>
                <p style={{ color: "red" }}>{databasesettingErrors.port}</p>

                <div className="form-group">
                  <label>Database Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="databaseName"
                    aria-rowspan={3}
                    name="databaseName"
                    value={databasesetting.databaseName}
                    onChange={handleChange}
                  />
                </div>
                <p style={{ color: "red" }}>
                  {databasesettingErrors.databaseName}
                </p>

                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userName"
                    aria-rowspan={3}
                    name="userName"
                    value={databasesetting.userName}
                    onChange={handleChange}
                  />
                </div>
                <p style={{ color: "red" }}>{databasesettingErrors.username}</p>

                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    aria-rowspan={3}
                    name="password"
                    value={databasesetting.password}
                    onChange={handleChange}
                  />
                </div>
                <p style={{ color: "red" }}>{databasesettingErrors.password}</p>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                {databasesetting.id == null ? (
                  <button
                    type="submit"
                    style={{ padding:"6px",borderRadius:"4px"}}
                    onClick={handleSubmit}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={(e) =>
                      UpdateDatabaseSettings(databasesetting.id, e)
                    }
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DatabaseSetting;
