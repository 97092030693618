import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../btn/btn.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function EditOrganization() {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateToOrganizations = () => {
    navigate("/organizations");
  };
  let [loading, setLoading] = useState(true);
  let [organization, setOrganization] = useState({
    name: "",
    registrationNumber: "",
    gstNumber: "",
    organizationAddress: {
      addressLineFirst: "",
      addressLineSecond: "",
      city: "",
      state: "",
      pinCode: "",
      country: "",
    },
  });

  const handleChange = (prop) => (event) => {
    const { name, value } = event.target;
    setOrganization({ ...organization, [prop]: event.target.value });

    if (
      prop === "addressLineFirst" ||
      prop === "addressLineSecond" ||
      prop === "city" ||
      prop === "state" ||
      prop === "pinCode" ||
      prop === "country"
    ) {
      setOrganization((prevOrganization) => {
        const newOrganization = { ...prevOrganization };
        newOrganization.organizationAddress[prop] = event.target.value;
        return newOrganization;
      });
    }
  };

  useEffect(() => {
    getOrganizationDetails(id);

    setOrganization({
      name: "",
      registrationNumber: "",
      gstNumber: "",
      organizationAddress: {
        addressLineFirst: "",
        addressLineSecond: "",
        city: "",
        state: "",
        pinCode: "",
        country: "",
      },
    });
    setLoading(false);
  }, []);

  // Get Organization Details

  const getOrganizationDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/organization/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setOrganization({
          name: response.data.name,
          registrationNumber: response.data.registrationNumber,
          gstNumber: response.data.gstNumber,
          organizationAddress: {
            addressLineFirst:
              response.data.organizationAddress.addressLineFirst,
            addressLineSecond:
              response.data.organizationAddress.addressLineSecond,
            city: response.data.organizationAddress.city,
            state: response.data.organizationAddress.state,
            pinCode: response.data.organizationAddress.pinCode,
            country: response.data.organizationAddress.country,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Add Organization
  const addOrganization = async () => {
    let result = await fetch(`${process.env.REACT_APP_API_URL}/organization/`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      body:JSON.stringify({
        id: id,
        name: organization.name,
        registrationNumber: organization.registrationNumber,
        gstNumber: organization.gstNumber,
        organizationAddress: {
          addressLineFirst: organization.organizationAddress.addressLineFirst,
          addressLineSecond: organization.organizationAddress.addressLineSecond,
          city: organization.organizationAddress.city,
          state: organization.organizationAddress.state,
          pinCode: organization.organizationAddress.pinCode,
          country: organization.organizationAddress.country,
          createdBy: JSON.parse(localStorage.getItem("user_info")).id,
        },
      }),
    }
  );
  if (result.status==200) {   
    navigate("/organizations");
  } 
  else if(result.status==400){
  alert("Bad request")
  }
  else{
    localStorage.clear("user_info");
    navigate("/");
  }
  };

 


  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Organization</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create New Organization
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />

          <form role="form">
            <h4>Basic Information</h4>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={organization.name}
                    onChange={handleChange("name")}
                    onBlur={handleChange("name")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Registration Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="registrationNumber"
                      value={organization.registrationNumber}
                      onChange={handleChange("registrationNumber")}
                      onBlur={handleChange("registrationNumber")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>GST Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="gstNumber"
                      value={organization.gstNumber}
                      onChange={handleChange("gstNumber")}
                      onBlur={handleChange("gstNumber")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <h4>Address</h4>
              <hr />
              <div className="col-md-6">
                <div className="form-group">
                  <label>Address Line First</label>
                  <input
                    type="text"
                    className="form-control"
                    id="addressLineFirst"
                    value={organization.organizationAddress.addressLineFirst}
                    onChange={handleChange("addressLineFirst")}
                    onBlur={handleChange("addressLineFirst")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Address Line Second/Landmark(s)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="addressLineSecond"
                    value={organization.organizationAddress.addressLineSecond}
                    onChange={handleChange("addressLineSecond")}
                    onBlur={handleChange("addressLineSecond")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    value={organization.organizationAddress.city}
                    onChange={handleChange("city")}
                    onBlur={handleChange("city")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    value={organization.organizationAddress.state}
                    onChange={handleChange("state")}
                    onBlur={handleChange("state")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Pin code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pinCode"
                    value={organization.organizationAddress.pinCode}
                    onChange={handleChange("pinCode")}
                    onBlur={handleChange("pinCode")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    value={organization.organizationAddress.country}
                    onChange={handleChange("country")}
                    onBlur={handleChange("country")}
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                <button
                  type="button"
                  onClick={addOrganization}
                  style={{
                    padding: "5px",
                    marginRight: "5px",
                    borderRadius: "4px",
                  }}
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={navigateToOrganizations}
                  style={{ padding: "5px", borderRadius: "4px" }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditOrganization;
