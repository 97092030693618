import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function EditMenu() {
  let [loading, setLoading] = useState(true);
  let [menu, setMenu] = useState({ name: "", description: "" });
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateToMenus = () => {
    navigate("/project/" + localStorage.getItem("project_id") + "/menus");
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setMenu((prevData) => ({
      ...prevData,
      [id]: fieldValue,
    }));
  };

  useEffect(() => {
    getProjectMenuDetails(id);
    setMenu({ name: "", description: "", pageId: "", show: false });
    setLoading(false);
  }, []);

  // Get Project Menu Details
  const getProjectMenuDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/menu/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setMenu({
          name: response.data.name,
          description: response.data.description,
          pageId: response.data.pageId,
          show: response.data.show
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Add Project Menu Details
  const addProjectMenu = async () => {
    setLoading(true);

    let result = await fetch(`${process.env.REACT_APP_API_URL}/menu/`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
        body: JSON.stringify({
          id: id,
          name: menu.name,
          description: menu.description,
          projectId: localStorage.getItem("project_id"),
          createdBy: JSON.parse(localStorage.getItem("user_info")).id,
          number: 1,
          show: menu.show,
          pageId: menu.pageId
        }),
      }
    );

    if (result.status == 200) {
      navigate("/project/" + localStorage.getItem("project_id") + "/menus");
    }
    else if (result.status == 400) {
      alert("Bad request")
    }
    else {
      localStorage.clear("user_info");
      navigate("/");
    }

  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Menu</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>

                  <li className="breadcrumb-item">
                    <Link
                      to={
                        "/project/" +
                        localStorage.getItem("project_id") +
                        "/menus"
                      }
                    >
                      Menus
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Menu</li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />

          <form role="form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={menu.name}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    aria-rowspan={3}
                    value={menu.description}
                    onChange={handleChange}
                    onBlur={handleChange}
                  ></textarea>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="show"
                    name="show"
                    checked={menu.show}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="showEntityField">
                    Show Menu
                  </label>
                </div>

              </div>
            </div>

            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                <button
                  type="button"
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                  onClick={addProjectMenu}
                >
                  Update
                </button>
                <button
                  type="button"
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                  onClick={navigateToMenus}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditMenu;
