import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
// import "./index.css"
import UploadIcon from "@mui/icons-material/Upload";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./listpages.css";
import {
  Button,
  Dialog,
  DialogTitle, 
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListPages() {
  const { id } = useParams();
  const { project_id } = useParams();
  const navigate = useNavigate();
  const navigateToCreatePage = () => {
    navigate("/create-page/" + localStorage.getItem("project_id"));
  };
  const navigateProjectDetails = () => {
    navigate("/project-details/" + id);
  };
  const navigateProjects = () => {
    navigate("/projects");
  };

  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupforMenu, setPopupforMenu] = useState(false);
  const [entitys, setEntitys] = useState([]);
  const [selects, setSelects] = useState("");
  const [selectedEntity, setSelectedEntity] = useState("");

  const [menus, setMenus] = useState([]);
  const [selectedPageDetails, setSelectedPageDetails] = useState({});
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [pageCount, setPageCount] = useState(0);
  const [selectedMenuValue, setSelectedMenuValue] = useState("");
  const [entityPopUp, setEntityPopUp]= useState(false)

  useEffect(() => {
    setLoading(true);
    const getPagesData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/page/project/${id}?page=0`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        })
        .then((response) => {
          setPages(response.data.content);
          const total = response.data.totalElements;
          setPageCount(Math.ceil(total / 10));
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };
    getPagesData();
  }, []);

  useEffect(() => {
    setLoading(true);

    getEntitysData();
  }, []);

  //getting entity data
  const getEntitysData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/entity/project/${localStorage.getItem(
          "project_id"
        )}/all`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      )
      .then((response) => {
        setEntitys(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleChange = (e, item, index) => {
    pages[index].mappedEntityId = e.target.value;
    setSelectedEntity(e.target.value);
    setSelectedPageDetails(pages[index]);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/page`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      data: pages[index],
    };

    axios(config)
      .then(function (response) {
        setPopup(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePopup = (item) => {
    setPopup(true);
    setSelectedPageDetails({
      id: item.id,
      name: item.name,
      description: item.description,
      createdBy: item.createdBy,
      isActive: item.isActive,
      deleted: item.deleted,
      projectId: item.projectId,
      pageType: item.pageType,
      mappedEntityId: item.mappedEntityId,
    });
  };

  useEffect(() => {
    setLoading(true);
    getMenusData();
  }, []);

  const getMenusData = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/menu/project/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setMenus(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const setSelectedMenu = (menuVal, index) => {
    setSelectedMenuValue(menuVal);
    updateMenuItem(menuVal, pages[index].id);
  };

  const handleMenuChange = (event, index) => {
    updateMenuItem(menus[event.target.selectedIndex - 1].id, pages[index].id);
    menus[event.target.selectedIndex - 1].pageId = pages[index].id;
  };

  const updateMenuItem = (selectedMenuId, selectedPageId) => {
    let payload = {};
    payload["menuId"] = selectedMenuId;
    payload["pageId"] = selectedPageId;
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/menu/${selectedMenuId}/page`,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        setPopupforMenu(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePopupforMenu = (item) => {
    setPopupforMenu(true);
    setSelectedPageDetails({
      id: item.id,
      name: item.name,
      description: item.description,
      createdBy: item.createdBy,
      isActive: item.isActive,
      deleted: item.deleted,
      pageType: item.pageType,
      projectId: item.projectId,
      mappedEntityId: item.mappedEntityId,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePass = (item) => {
    item.mappedEntityId != null
      ? navigate(
          item.pageType == "list" || item.pageType == "List"
            ? `/create-list-layout/${item.id}`
            : `/create-page-layout/${item.id}`
        )
      // : alert("Please select entity");
      :handleEntity()
  };


  const handleEntity=()=>{
    setEntityPopUp(true)
  }

  //for uploading xlsx files
  const handleUploadClick = () => {
    const data = new FormData();
    data.append("file", selectedFile);
    data.append("projectId", localStorage.getItem("project_id"));
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/bulk-data/pages`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log("re status", response.status);
        console.log("res", response.data.message);
        if (response.status == 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //for uploading xlsx files
  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  var i = 1;

  const fetchPages = async (currentPage) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/page/project/${id}?page=${currentPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected;
    const dataFromServer = await fetchPages(currentPage);
    setPages(dataFromServer.content);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Pages</h2>
          <hr />

          <div className="row">
            <div className="col-md-8">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/project-details/" + localStorage.getItem("project_id")
                      }
                    >
                      Details
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Page</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-4" style={{ display: "flex" }}>
              <div>
                <button
                  style={{
                    cursor: "pointer",
                    marginRight: "5px",
                    padding: "5px",
                    borderRadius: "4px",
                  }}
                  onClick={navigateToCreatePage}
                >
                  Add New Page
                </button>
              </div>
              <div
                className="file-inputs"
                style={{ marginLeft: 10, height: 40 }}
              >
                <input
                  className="inputdata"
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleFileChange}
                />
                <button className="buttondata">
                  <i>
                    <UploadIcon />
                  </i>
                  Upload
                </button>
              </div>
              {selectedFile ? handleUploadClick() : ""}
            </div>
          </div>

          <hr />

          <table className="table">
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Map entity</th>
                <th>Map menu</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pages &&
                pages.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <select
                          id={"entityId" + index}
                          value={item.mappedEntityId}
                          defaultValue={item.mappedEntityId}
                          label="Entities"
                          onChange={(e) => handleChange(e, item, index)}
                          className="form-select"
                        >
                          <option>-</option>
                          {entitys.map((entity) => {
                            return (
                              <option
                                key={entity.id}
                                name={entity.id}
                                value={entity.id}
                              >
                                {entity.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          name={index + item.id}
                          defaultValue={item.id}
                          value={item.id}
                          onChange={(e) => handleMenuChange(e, index)}
                          label="Menus"
                          className="form-select"
                        >
                          <option>-</option>
                          {menus.map((menu, menuIndex) => {
                            return (
                              <option
                                value={menu.pageId}
                                name={menu.id}
                                id={menu.id}
                              >
                                {menu.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <Link to={`/pages/editpage/${item.id}`}>
                          <button
                            type="button"
                            style={{
                              cursor: "pointer",
                              marginRight: "5px",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          >
                            Edit
                          </button>
                        </Link>
                        &nbsp;
                        <button
                          type="button"
                          style={{
                            cursor: "pointer",
                            marginRight: "5px",
                            padding: "5px",
                            borderRadius: "4px",
                          }}
                          onClick={() => handlePass(item)}
                        >
                          Page layout
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <ReactPaginate
            breakLabel="..."
            previousLabel="< previous"
            nextLabel="next >"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center "}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <Dialog
        open={entityPopUp}
        onClose={() => setEntityPopUp(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Select Entity"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select Map Entity from select box before proceed further
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setEntityPopUp(false)}
            autoFocus
            sx={{ color: "#4C2C7D" }}
          >
           Ok
          </Button>
        </DialogActions>
      </Dialog>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default ListPages;
