import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListSubMenus() {
  const { menu_id } = useParams();
  const navigate = useNavigate();
  const navigateToCreateSubMenu = () => {
    navigate("/create-sub-menu/" + menu_id);
  };

  let [loading, setLoading] = useState(false);
  let [menus, setMenus] = useState([]);

  useEffect(() => {
    getMenusData();
  }, []);

  // List Projects

  const getMenusData = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/submenu/menu/${menu_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setMenus(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    /* createdAt, createdBy, id, name*/
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>SubMenus</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/project-details/" + localStorage.getItem("project_id")
                      }
                    >
                      Details
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        "/project/" +
                        localStorage.getItem("project_id") +
                        "/menus"
                      }
                    >
                      Menus
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Submenus</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-info float-right"
                onClick={navigateToCreateSubMenu}
              >
                Add New SubMenu
              </button>
            </div>
          </div>

          <hr />

          <table className="table">
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Created By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {menus.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.createdBy}</td>
                    <td>
                      <Link to={`/submenus/editsubmenu/${item.id}`}>
                        <button
                          type="button"
                          style={{ cursor: "pointer", marginRight: "5px" }}
                          className="btn btn-success"
                        >
                          Edit
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default ListSubMenus;
