import React, { useState, useEffect } from 'react';

const TableWithCheckboxes = ({ data, listPageMainContent, onChange }) => {
  const handleCheckboxChange = (event, mappedEntityFieldId) => {
    const { checked } = event.target;
    const existingIndex = listPageMainContent.findIndex(
      (item) => item.mappedEntityFieldId === mappedEntityFieldId
    );

    if (checked && existingIndex === -1) {
      // Add the value to the listPageMainContent if checked and not already present
      onChange([
        ...listPageMainContent,
        {
          columnNumber: '',
          fieldLabel: '',
          mappedEntityFieldId,
        },
      ]);
    } else if (!checked && existingIndex !== -1) {
      // Remove the value from the listPageMainContent if unchecked and present
      const updatedList = listPageMainContent.filter(
        (item) => item.mappedEntityFieldId !== mappedEntityFieldId
      );
      onChange(updatedList);
    }
  };

  const handleInputChange = (event, mappedEntityFieldId, fieldName) => {
    const { value } = event.target;
    const updatedList = listPageMainContent.map((item) => {
      if (item.mappedEntityFieldId === mappedEntityFieldId) {
        return {
          ...item,
          [fieldName]: value,
        };
      }
      return item;
    });
    onChange(updatedList);
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Sr. No.</th>
          <th>Field Name</th>
          <th>Column Number</th>
          <th>Field Label</th>
          <th>Display</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.name}</td>
            <td>
              <input
                type="number"
                className="form-control"
                value={
                  listPageMainContent.find(
                    (selectedItem) =>
                      selectedItem.mappedEntityFieldId === item.name
                  )?.columnNumber || ''
                }
                onChange={(e) =>
                  handleInputChange(e, item.name, 'columnNumber')
                }
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                value={
                  listPageMainContent.find(
                    (selectedItem) =>
                      selectedItem.mappedEntityFieldId === item.name
                  )?.fieldLabel || ''
                }
                onChange={(e) => handleInputChange(e, item.name, 'fieldLabel')}
              />
            </td>
            <td>
              <input
                type="checkbox"
                className="form-check-input"
                checked={
                  listPageMainContent.some(
                    (selectedItem) => selectedItem.mappedEntityFieldId === item.name
                  )
                }
                onChange={(e) => handleCheckboxChange(e, item.name)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableWithCheckboxes;
