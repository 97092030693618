import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function AddSubMenu() {
  let [loading, setLoading] = useState(true);
  let [submenu, setSubMenu] = useState({ name: "", description: "" });
  let [subMenuErrors, setSubMenuErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const { menu_id } = useParams();
  const navigate = useNavigate();

  const navigateToMenus = () => {
    navigate("/project/menus/submenus/" + localStorage.getItem("menu_id"));
  };

  const handleChange = (prop) => (event) => {
    const { name, value } = event.target;
    setSubMenu({ ...submenu, [prop]: event.target.value });
  };

  // Add Project Menu Details
  // const addProjectMenu = () => {
  //     setLoading(true);

  //     axios(config);
  //     navigateToMenus();

  // }

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubMenuErrors(validate(submenu));
    setIsSubmit(true);

    // navigateToEntitys();
  };

  useEffect(() => {
    if (Object.keys(subMenuErrors).length === 0 && isSubmit) {
      if (isSubmit) {
        onSubmitData()
      }
    }
  }, [subMenuErrors]);



  const onSubmitData = async () => {
   
    let result = await fetch(`${process.env.REACT_APP_API_URL}/submenu/`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
        body:JSON.stringify({
          name: submenu.name,
          description: submenu.description,
          projectId: localStorage.getItem("project_id"),
          menuId: localStorage.getItem("menu_id"),
        }),
      }
    );
    
    if (result.status==200) {   
      navigate("/project/menus/submenus/" + localStorage.getItem("menu_id"));
    } 
    else if(result.status==400){
      alert("Bad request")
      }
    else{
      localStorage.clear("user_info");
      navigate("/");
    }
 
};



  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = " * Name is Required";
    }
    if (!values.description) {
      errors.description = " * Description is Required";
    }

    return errors;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create SubMenu</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        "/project/" +
                        localStorage.getItem("project_id") +
                        "/menus"
                      }
                    >
                      Menus
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        "/project/menus/submenus/" +
                        localStorage.getItem("menu_id")
                      }
                    >
                      SubMenus
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Create New Submenu</li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />

          <form role="form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={submenu.name}
                    onChange={handleChange("name")}
                    onBlur={handleChange("name")}
                  />
                </div>
                <p style={{ color: "red" }}>{subMenuErrors.name}</p>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    name="decsription"
                    aria-rowspan={3}
                    value={submenu.description}
                    onChange={handleChange("description")}
                    onBlur={handleChange("description")}
                  ></textarea>
                </div>
                <p style={{ color: "red" }}>{subMenuErrors.description}</p>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                {/* <button type="submit" className="btn btn-primary me-2" onClick={addProjectMenu}>
                                    Submit
                                </button> */}
                <button type="submit" className="btn btn-primary me-2">
                  Submit
                </button>

                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={navigateToMenus}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <ClockLoader
                color="#36d6b9"
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            /> */}
    </div>
  );
}

export default AddSubMenu;
