const Select = ({ fieldLabel, defaultValues }) => {
  let selectboxValues = null;
  let isValidJson = false;
  try {
    // selectboxValues = JSON.parse(defaultValues);
    selectboxValues = null;
    isValidJson = true;
  } catch (e) {
    isValidJson = false;
  }
  return (
    <div className="form-group">
      <label htmlFor="selectbox">{fieldLabel}</label>
      {isValidJson && (
        <select className="form-control form-select" id="selectbox">
          <option value="">Select value</option>
          {selectboxValues != null &&
            selectboxValues.map((val, index) => (
              <option value={val.value}>{val.name}</option>
            ))}
        </select>
      )}
    </div>
  );
};
export default Select;
