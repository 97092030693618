import UploadIcon from "@mui/icons-material/Upload";
import axios from "axios";
import * as FileSaver from "file-saver";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../btn/btn.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
import "./listentities.css";
import { Input } from "@mui/material";
import { Search } from "@mui/icons-material";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListEntities() {
  const { id } = useParams();
  const { project_id } = useParams();
  const navigate = useNavigate();
  const navigateToCreateEntity = () => {
    navigate("/entity/");
  };
  const navigateProjectDetails = () => {
    navigate("/project-details/" + id);
  };
  const navigateProjects = () => {
    navigate("/projects");
  };
  const navigatePagesByEntity = (entityId) => {
    navigate("/pages/entities/" + entityId);
  };

  let [loading, setLoading] = useState(false);
  let [entitys, setEntitys] = useState([]);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [selectedDataFile, setSelectedDataFile] = useState(undefined);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    getEntitysData();
  }, []);

  //for uploading xlsx files
  const handleDataUploadClick = (selectedFileToUpload, entityId) => {
    const data = new FormData();
    data.append("file", selectedFileToUpload);
    data.append("projectId", localStorage.getItem("project_id"));
    data.append("entityId", entityId);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/bulk-data/entities-data`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.status == 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //for uploading xlsx files
  const handleDataUploadFileChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setSelectedDataFile(e.target.files[0]);
      handleDataUploadClick(e.target.files[0], e.target.id);
    }
  };

  // List Entities
  const getEntitysData = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/entity/project/${project_id}?page=0`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      )
      .then((response) => {
        setEntitys(response.data.content);
        const total = response.data.totalElements;
        setPageCount(Math.ceil(total / 10));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Get data upload template data
  const downloadDataUploadTemplate = async (entityId, entityName) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/entity/template/${entityId}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, entityName + ".xlsx");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSet = (item) => {
    let setvalue = item;
    localStorage.setItem("entity_id", setvalue);
    navigate(`/entity-fields/${localStorage.getItem("entity_id")}`);
  };

  const handleUploadClick = () => {
    const data = new FormData();
    data.append("file", selectedFile);
    data.append("projectId", project_id);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/bulk-data/entities`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.status == 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const fetchEntitys = async (currentPage) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/entity/project/${project_id}?page=${currentPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected;
    const dataFromServer = await fetchEntitys(currentPage);
    setEntitys(dataFromServer.content);
  };

  var i = 1;
  return (
    /* createdAt, createdBy, id, name*/
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Entities</h2>
          <hr />

          <div className="row">
            <div className="col-md-8">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>

                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/project-details/" + localStorage.getItem("project_id")
                      }
                    >
                      Details
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Entities</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-4" style={{ display: "flex" }}>
              <div>
                <button
                  style={{
                    cursor: "pointer",
                    padding: "5px",
                    marginRight: "4px",
                    borderRadius: "4px",
                  }}
                  onClick={navigateToCreateEntity}
                >
                  {/* <i>  < AddCircleOutlineIcon /> </i> */}
                  Add New Entity
                </button>
              </div>

              <div
                className="file-inputs"
                style={{ marginLeft: 10, height: 40 }}
              >
                <input
                  className="inputdata"
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleFileChange}
                />
                <button className="buttondata">
                  <i>
                    <UploadIcon />
                  </i>
                  Upload
                </button>
              </div>
              {selectedFile ? handleUploadClick() : ""}
            </div>
          </div>

          <hr />
          <div className="row">
            <div className="col-md-4">
              <div class="input-group rounded">
                <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
              </div>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Created By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {entitys &&
                entitys.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.createdBy}</td>
                      <td>
                        <Link to={`/entity/${item.id}`}>
                          <button
                            type="button"
                            style={{
                              cursor: "pointer",
                              marginRight: "4px",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          >
                            Edit
                          </button>
                        </Link>
                        <button
                          type="button"
                          style={{
                            cursor: "pointer",
                            marginRight: "4px",
                            padding: "5px",
                            borderRadius: "4px",
                          }}
                          onClick={(event) => handleSet(item.id)}
                        >
                          Fields
                        </button>
                        <input
                          className="uploaddata"
                          type="file"
                          name="file"
                          id={item.id}
                          onChange={handleDataUploadFileChange}
                        />
                        <button
                          type="button"
                          style={{
                            cursor: "pointer",
                            marginRight: "2px",
                            padding: "5px",
                            borderRadius: "4px",
                          }}
                          id="uploadbtn"
                        >
                          Upload data
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            downloadDataUploadTemplate(item.id, item.name)
                          }
                          style={{
                            cursor: "pointer",
                            padding: "5px",
                            marginRight: "4px",
                            borderRadius: "4px",
                          }}
                        >
                          Download Template
                        </button>
                        <button
                          type="button"
                          onClick={() => navigatePagesByEntity(item.id)}
                          style={{
                            cursor: "pointer",
                            padding: "5px",
                            borderRadius: "4px",
                          }}
                        >
                          Pages
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <ReactPaginate
            breakLabel="..."
            previousLabel="< previous"
            nextLabel="next >"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center "}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default ListEntities;
