import axios from "axios";
import * as FileSaver from "file-saver";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ViewTemplate() {
  const { templateId } = useParams();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState({});

  useEffect(() => {
    setLoading(true);
    getTemplateDetails();
  }, []);

  // List Templates
  const getTemplateDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/templates/${templateId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setTemplate(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Download template
  const downloadBulkDataUploadTemplate = async (templateId, templateName) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/templates/${templateId}/download`,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user_info")).accessToken,
          },
        }
      )
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, templateName + ".xlsx");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <div className="row">
            <div className="col-md-9">
              <h4>Template Details - {template.name}</h4>
            </div>
            <div className="col-md-3 justify-right">
              <button
                type="button"
                className="btn btn-info"
                onClick={() =>
                  downloadBulkDataUploadTemplate(template.id, template.name)
                }
              >
                Download Template
              </button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-9">
              <b>Description: </b>
              {template.description}
            </div>
          </div>
          <hr />
          <h5>Column Names</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>Header Name</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {template &&
                template.headers &&
                template.headers.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <h5>Notes</h5>
          <ul>
            {template &&
              template.notes &&
              template.notes.map((item, index) => {
                return <li key={index + 1}> {item} </li>;
              })}
          </ul>
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default ViewTemplate;
