import React, { useState } from 'react';
import axios from "axios";

const SelectionElementConfigurationComponent = ({selectionControlData, handleSelectionControlChange, setSelectionControlData}) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [showButton, setShowButton] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [apiEndpoint, setApiEndpoint] = useState('');
    const [authToken, setAuthToken] = useState('');
    const [showConnectionTest, setShowConnectionTest] = useState(false);
    const [entities, setEntities] = useState([]);
    const [entityFieldsData, setEntityFieldsData] = useState([]);

    const handleOptionChange = (event) => {
        const option = event.target.value;
        setSelectedOption(option);
        setShowButton(option === 'Custom');
        setShowConnectionTest(option === 'API');
        setSelectionControlData((prevData) => ({
            ...prevData,
            ["setFieldDataFrom"]: option,
          }));
        if (option === 'Entity') {
            getEntitiesData();
        }
    };

    const handleModalButtonClick = () => {
        // Perform any necessary action when the button is clicked
        // In this example, we simply toggle the modalData state
        setShowModal(true);
        setModalData([]);
    };

    const handleAddRow = () => {
        // Add a new row to the modalData state
        setModalData([...modalData, { id: '', name: '' }]);
    };

    const handleRowChange = (index, field, value) => {
        // Update the corresponding field value in the modalData state
        const updatedData = [...modalData];
        updatedData[index][field] = value;
        setModalData(updatedData);
    };

    const handleApiEndpointChange = (event) => {
        setApiEndpoint(event.target.value);
    };

    const handleAuthTokenChange = (event) => {
        setAuthToken(event.target.value);
    };

    const handleEntityTypeChange = (event) => {
        const entityType = event.target.value;
        setSelectionControlData((prevData) => ({
          ...prevData,
          ["entityType"]: entityType,
        }));    
        getEntityFieldsData(entityType);
    };

    //getting entity data
    const getEntitiesData = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/entity/project/${localStorage.getItem(
                    "project_id"
                )}/all`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "*/*",
                        Authorization:
                            "Bearer " +
                            JSON.parse(localStorage.getItem("user_info")).accessToken,
                    },
                }
            )
            .then((response) => {
                setEntities(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getEntityFieldsData = async (entityId) => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}/entity-field/entity/${entityId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "*/*",
                    Authorization:
                        "Bearer " +
                        JSON.parse(localStorage.getItem("user_info")).accessToken,
                },
            })
            .then((response) => {
                setEntityFieldsData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div>
            <div className="form-group">
                <label htmlFor="nameSelection">
                    Use Selection Data From{" "}
                    <span style={{ color: "red" }}>*</span>
                </label>
            </div>
            <div>
                <select  value={selectionControlData?.setFieldDataFrom} id="entityType" onChange={handleOptionChange} className="form-select">
                    <option value="">Select an option</option>
                    <option value="Custom">Custom values</option>
                    <option value="API">API</option>
                    <option value="Entity">Existing Entity</option>
                </select>
            </div>

            {
                showButton && (
                    <button onClick={handleModalButtonClick}>Open Modal</button>
                )
            }

            {
                showModal && (
                    <div>
                        {/* Modal content */}
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modalData.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type="text"
                                                value={row.id}
                                                onChange={(e) =>
                                                    handleRowChange(index, 'id', e.target.value)
                                                }
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={row.name}
                                                onChange={(e) =>
                                                    handleRowChange(index, 'name', e.target.value)
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {selectedOption === 'Custom' && (
                            <button onClick={handleAddRow}>Add Row</button>
                        )}
                    </div>
                )
            }

            {
                showConnectionTest && (
                    <div>
                        {/* API endpoint and auth token input */}
                        <input
                            type="text"
                            value={apiEndpoint}
                            onChange={handleApiEndpointChange}
                            placeholder="API Endpoint"
                        />
                        <input
                            type="text"
                            value={authToken}
                            onChange={handleAuthTokenChange}
                            placeholder="Auth Token"
                        />

                        {/* Connection test button */}
                        <button>Test Connection</button>
                    </div>
                )
            }

            {
                selectedOption === 'Entity' && (
                    <div>
                        <div className="form-group">
                            <label htmlFor="entitySelection">
                                Selection Entity{" "}
                                <span style={{ color: "red" }}>*</span>
                            </label>
                        </div>
                        <div className="form-group">
                            <select value={selectionControlData.entityType} id="entityType" onChange={handleEntityTypeChange} className="form-select">
                                <option>Select Existing Entity</option>
                                {entities && entities.map((entity) => {
                                    return (
                                        <option
                                            key={entity.id}
                                            name={entity.id}
                                            value={entity.id}
                                        >
                                            {entity.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="nameSelection">
                                Selection Field Name{" "}
                                <span style={{ color: "red" }}>*</span>
                            </label>
                        </div>
                        <div className="form-group">
                            <select className="form-select"  id="valueField" value={selectionControlData?.valueField} onChange={handleSelectionControlChange}>
                                <option>Select Entity Name</option>
                                {entityFieldsData && entityFieldsData.map((entity) => {
                                    return (
                                        <option
                                            key={entity.id}
                                            name={entity.name}
                                            value={entity.name}
                                        >
                                            {entity.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="idSelection">
                                Selection Field Id{" "}
                                <span style={{ color: "red" }}>*</span>
                            </label>
                        </div>
                        <div className="form-group">
                            <select className="form-select" id="nameField" value={selectionControlData?.nameField} onChange={handleSelectionControlChange}>
                                <option>Select Entity Id</option>
                                {entityFieldsData && entityFieldsData.map((entity) => {
                                    return (
                                        <option
                                            key={entity.id}
                                            name={entity.name}
                                            value={entity.name}
                                        >
                                            {entity.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>


                    </div>
                )
            }
        </div >
    );
};

export default SelectionElementConfigurationComponent;
