import { Button } from "react-bootstrap";
import Checkbox from "./elementfiles/Checkbox";
import DatePicker from "./elementfiles/DatePicker";
import Input from "./elementfiles/Input";
import Radiobutton from "./elementfiles/Radiobutton";
import Select from "./elementfiles/Select";
import TextArea from "./elementfiles/TextArea";

const Element = ({
  field: {
    htmlComponentId,
    field_id,
    fieldLabel,
    field_placeholder,
    mappedEntityFieldId,
    defaultValues,
    isMandatoryField,
  },
}) => {
  switch (htmlComponentId) {
    case "Textbox":
      return (
        <Input
          field_id={field_id}
          isMandatoryField={isMandatoryField}
          fieldLabel={fieldLabel}
          field_placeholder={field_placeholder}
          mappedEntityFieldId={mappedEntityFieldId}
        />
      );
    case "Selectbox":
      return (
        <Select
          field_id={field_id}
          fieldLabel={fieldLabel}
          field_placeholder={field_placeholder}
          mappedEntityFieldId={mappedEntityFieldId}
          defaultValues={defaultValues}
        />
      );
    case "Textarea":
      return (
        <TextArea
          field_id={field_id}
          fieldLabel={fieldLabel}
          field_placeholder={field_placeholder}
          mappedEntityFieldId={mappedEntityFieldId}
        />
      );
      case "Date Picker":
        return (
          <DatePicker
            field_id={field_id}
            fieldLabel={fieldLabel}
            field_placeholder={field_placeholder}
            mappedEntityFieldId={mappedEntityFieldId}
          />
        );
    case "Radio":
      return (
        <Radiobutton
          field_id={field_id}
          fieldLabel={fieldLabel}
          field_placeholder={field_placeholder}
          mappedEntityFieldId={mappedEntityFieldId}
          defaultValues={defaultValues}
        />
      );
    case "Checkbox":
      return (
        <Checkbox
          field_id={field_id}
          fieldLabel={fieldLabel}
          field_placeholder={field_placeholder}
          mappedEntityFieldId={mappedEntityFieldId}
          defaultValues={defaultValues}
        />
      );
    case "Element Group Title":
      return (
        <div>
          <h5>{fieldLabel}</h5> <hr />
        </div>
      );
    case "Page Header":
      return (
        <div>
          <h3>{fieldLabel}</h3> <hr />
        </div>
      );
    case "Button":
      return (
        <Button
          field_id={field_id}
          fieldLabel={fieldLabel}
        >{fieldLabel}</Button>
      );
  
    default:
      return null;
  }
};
export default Element;
