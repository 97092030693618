import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function AddOrganizationMember() {
  const { organization_id } = useParams();
  // const { organization_member_id } = useParams()
  const navigate = useNavigate();
  let [organizationMemberErrors, setOrganizationMemberErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const navigateToOrganizationMembers = () => {
    navigate(
      "/organization-members/" + localStorage.getItem("organization_id_members")
    );
  };

  let [organizationMember, setOrganizationMember] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    organizationId: "",
    memberRoles: [],
    isAdmin: false,
  });

  const handleChange = (name) => (event) => {
    const { name, value } = event.target;
    setOrganizationMember({ ...organizationMember, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setOrganizationMemberErrors(validate(organizationMember));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(organizationMemberErrors).length === 0 && isSubmit) {
      if (isSubmit) {
       
        onSubmitData()
      }
    }
  }, [organizationMemberErrors]);


  const onSubmitData = async () => {
   
    let result = await fetch(`${process.env.REACT_APP_API_URL}/organization-member/`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
        body:JSON.stringify({
          firstName: organizationMember.firstName,
          lastName: organizationMember.lastName,
          email: organizationMember.email,
          password: organizationMember.password,
          phoneNumber: organizationMember.phoneNumber,
          organizationId: localStorage.getItem("organization_id_members"),
          memberRoles: organizationMember.memberRoles,
          isAdmin: organizationMember.isAdmin,
        }),
      }
    );
    
    if (result.status==200) {   
      navigate("/organization-members/" +
      localStorage.getItem("organization_id_members"))
    } 
    else if(result.status==400){
      alert("Bad request")
      }
    else{
      localStorage.clear("user_info");
      navigate("/");
    }
 
};


  const validate = (values) => {
    const errors = {};
    // /^[0-9]+$/
    const regex_number = /^[0-9\b]+$/;
    const regex_email = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
    const reg_email = "/^[a-zA-Z0-9]+@[a-zA-Z0-9]+.[A-Za-z]+$/";
    if (!values.firstName) {
      errors.firstName = " * First Name is Required";
    }
    if (!values.lastName) {
      errors.lastName = " * Last Name is Required";
    }

    if (!values.email) {
      errors.email = " * Email is Required";
    }
    if (!values.password) {
      errors.password = " * Password is Required";
    } else if (values.password.length < 5) {
      errors.password = "* Password should be more than 5 character";
    } else if (values.password.length > 10) {
      errors.password = "* Password should be less than 10 character";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = " * Phone Number is Required";
    } else if (!regex_number.test(values.phoneNumber)) {
      errors.phoneNumber = "* Phone Number should be than 10 digit";
    }

    return errors;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Organization Member</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`/organization-members/${localStorage.getItem(
                        "organization_id_members"
                      )}`}
                    >
                      Organization Members
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create New Organization Member
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />

          <form role="form" onSubmit={handleSubmit}>
            <h4>Basic Information</h4>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={organizationMember.firstName}
                    onChange={handleChange("firstName")}
                    onBlur={handleChange("firstName")}
                  />
                </div>
                <p style={{ color: "red" }}>
                  {organizationMemberErrors.firstName}
                </p>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={organizationMember.lastName}
                    onChange={handleChange("lastName")}
                    onBlur={handleChange("lastName")}
                  />
                </div>
                <p style={{ color: "red" }}>
                  {organizationMemberErrors.lastName}
                </p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={organizationMember.email}
                      onChange={handleChange("email")}
                      onBlur={handleChange("email")}
                    />
                  </div>
                  <p style={{ color: "red" }}>
                    {organizationMemberErrors.email}
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={organizationMember.password}
                      onChange={handleChange("password")}
                      onBlur={handleChange("password")}
                    />
                  </div>
                  <p style={{ color: "red" }}>
                    {organizationMemberErrors.password}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={organizationMember.phoneNumber}
                      onChange={handleChange("phoneNumber")}
                      onBlur={handleChange("phoneNumber")}
                    />
                  </div>
                  <p style={{ color: "red" }}>
                    {organizationMemberErrors.phoneNumber}
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="isAdmin"
                      name="isAdmin"
                      value={organizationMember.isAdmin}
                      onChange={handleChange("isAdmin")}
                      onBlur={handleChange("isAdmin")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Is Admin
                    </label>
                    <p style={{ color: "red" }}>
                      {organizationMemberErrors.isAdmin}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                <button
                  type="submit"
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                  onClick={navigateToOrganizationMembers}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddOrganizationMember;
