import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import "../btn/btn.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListProjects() {
  const { organization_id } = useParams();
  const navigate = useNavigate();
  const navigateToCreateProject = () => {
    navigate("/create-project");
  };
  let [loading, setLoading] = useState(false);
  let [projects, setProjects] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    localStorage.setItem("organization_id", organization_id);
    const getProjectsData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/project/organization/${organization_id}?page=0`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("user_info")).accessToken,
            },
          }
        );
        setProjects(response.data.content);
        const total = response.data.totalElements;
        setPageCount(Math.ceil(total / 10));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getProjectsData();
  }, []);

  // Get projects data

  const fetchProjects = async (currentPage) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/project/organization/${organization_id}?page=${currentPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected;
    const dataFromServer = await fetchProjects(currentPage);
    setProjects(dataFromServer.content);
  };

  return (
    /* createdAt, createdBy, id, name*/
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Projects</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">Projects</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-3">
              <button
                style={{
                  padding: "5px",
                  marginRight: "5px",
                  borderRadius: "4px",
                }}
                onClick={navigateToCreateProject}
              >
                Add New Project
              </button>
            </div>
          </div>

          <hr />

          <table className="table">
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Created By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.createdBy}</td>
                    <td>
                      <Link to={`/project-details/${item.id}`}>
                        <button
                          type="button"
                          style={{
                            padding: "5px",
                            marginRight: "5px",
                            borderRadius: "4px",
                          }}
                        >
                          Details
                        </button>
                      </Link>
                      &nbsp;
                      <Link to={`/create-project/${item.id}`}>
                        <button
                          type="button"
                          style={{ padding: "5px", borderRadius: "4px" }}
                        >
                          Edit
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <ReactPaginate
            breakLabel="..."
            previousLabel="< previous"
            nextLabel="next >"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center "}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default ListProjects;
