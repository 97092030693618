import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import "../btn/btn.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListReports() {
  const { id } = useParams();
  const { project_id } = useParams();
  const navigate = useNavigate();
  const navigateToCreateReport = () => {
    navigate("/create-report");
  };
  let [loading, setLoading] = useState(false);
  let [reports, setReports] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const getReportsData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/report/project/${localStorage.getItem("project_id")}?page=0`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("user_info")).accessToken,
            },
          }
        );
        setReports(response.data.content);
        const total = response.data.totalElements;
        setPageCount(Math.ceil(total / 10));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getReportsData();
  }, []);

  // Get reports data
  const fetchReports = async (currentPage) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/report/project/${project_id}?page=${currentPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected;
    const dataFromServer = await fetchReports(currentPage);
    setReports(dataFromServer.content);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Reports</h2>
          <hr />

          <div className="row">
            <div className="col-md-8">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Reports</li>
                </ol>
              </nav>
            </div>

            <div className="col-md-4" style={{ display: "flex" }}>
              <div>
                <button
                  style={{
                    cursor: "pointer",
                    marginRight: "5px",
                    padding: "5px",
                    borderRadius: "4px",
                  }}
                  onClick={navigateToCreateReport}
                >
                  Add New Report
                </button>
              </div>
            </div>
          </div>
        
        <hr />

        <table className="table">
          <thead>
            <tr>
              <th>Sr no.</th>
              <th>Name</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>
                    <Link to={`/report/${item.id}`}>
                      <button
                        type="button"
                        style={{ padding: "5px", borderRadius: "4px" }}
                      >
                        Edit
                      </button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ReactPaginate
          breakLabel="..."
          previousLabel="< previous"
          nextLabel="next >"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center "}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          renderOnZeroPageCount={null}
        />
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
    </div>
  );
}

export default ListReports;
