import { Link } from "react-router-dom";

function NavigationBar(selectedMenu) {
  return (
    <div className="col-md-3">
      <div
        // className="d-flex flex-column flex-shrink-0 p-3"
        // className="col-md-2"
        style={{
         position:"fixed",
         height:"100%",
         left:0,
         width:"300px",
          overflow: "auto",
          backgroundColor: "#0093E9",
          backgroundImage: `linear-gradient(160deg, rgb(150 109 215) 0%, rgb(128, 208, 199) 100%)`,
          color: "#fff"
        }}
      >
        <a
          
          className="d-block p-3 link-dark text-decoration-none"
          title="Icon-only"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
        >
          <span className="fs-4" style={{ color: "#fff" }}>
            Instantaneous
          </span>
        </a>
        <hr style={{ height: "1.5px", color: "#fff" }} />
        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item">
            <Link
              to="/organizations"
              className={
                selectedMenu == "organizations"
                  ? "nav-link active text-limit"
                  : "nav-link text-white text-limit"
              }
              aria-current="page"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right-circle"
                viewBox="0 0 16 16"
              >
                <path
                  FillRule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                />
              </svg>
              <span style={{ marginLeft: "3%" }}></span>
              Organizations
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/templates"
              className={
                selectedMenu == "templates"
                  ? "nav-link active text-limit"
                  : "nav-link text-white text-limit"
              }
              aria-current="page"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right-circle"
                viewBox="0 0 16 16"
              >
                <path
                  FillRule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                />
              </svg>
              <span style={{ marginLeft: "3%" }}></span>
              Templates
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/html-components"
              className={
                selectedMenu == "templates"
                  ? "nav-link active text-limit"
                  : "nav-link text-white text-limit"
              }
              aria-current="page"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right-circle"
                viewBox="0 0 16 16"
              >
                <path
                  FillRule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                />
              </svg>
              <span style={{ marginLeft: "3%" }}></span>
              HTML Components
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NavigationBar;
