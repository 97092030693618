import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import "../btn/btn.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function ListOrganizationN() {
  let id = 1;
  const [organizations, setOrganizations] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const navigateToCreateOrganization = () => {
    navigate("/create-organization/");
  };
  const navigateProjects = () => {
    navigate("/projects");
  };

  useEffect(() => {
    const getOrganizationsData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/organization?page=0`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("user_info")).accessToken,
            },
          }
        );
        setOrganizations(response.data.content);
        const total = response.data.totalElements;
        setPageCount(Math.ceil(total / 10));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getOrganizationsData();
  }, []);

  const fetchOrganizations = async (currentPage) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/organization?page=${currentPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected;
    const dataFromServer = await fetchOrganizations(currentPage);
    setOrganizations(dataFromServer.content);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Organizations</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li
                    className="breadcrumb-item active"
                    onClick={navigateProjects}
                  >
                    <a>Organizations</a>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-3">
              <button
                onClick={navigateToCreateOrganization}
                style={{ padding: "5px", borderRadius: "4px" }}
              >
                Add New Organization
              </button>
            </div>
          </div>

          <hr />

          <table className="table">
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Created By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {organizations &&
                organizations.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{id++}</td>
                      <td>{item.name}</td>
                      <td>{item.createdBy}</td>
                      <td>
                        <Link to={`/projects/${item.id}`}>
                          <button
                            type="button"
                            style={{
                              cursor: "pointer",
                              padding: "5px",
                              marginRight: "5px",
                              borderRadius: "4px",
                            }}
                          >
                            Projects
                          </button>
                        </Link>
                        <Link to={`/organizations/editorganization/${item.id}`}>
                          <button
                            type="button"
                            style={{
                              cursor: "pointer",
                              padding: "5px",
                              marginRight: "5px",
                              borderRadius: "4px",
                            }}
                          >
                            Edit
                          </button>
                        </Link>
                        <Link to={`/organization-members/${item.id}`}>
                          <button
                            type="button"
                            style={{
                              cursor: "pointer",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          >
                            Members
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                  id++;
                })}
            </tbody>
          </table>

          <ReactPaginate
            breakLabel="..."
            previousLabel="< previous"
            nextLabel="next >"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center "}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default ListOrganizationN;
