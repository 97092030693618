import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function EditPage() {
  const { id } = useParams();

  const navigate = useNavigate();
  const navigateToPages = () => {
    navigate("/project/" + localStorage.getItem("project_id") + "/pages");
  };
  let [loading, setLoading] = useState(true);
  let [page, setPage] = useState({ name: "", description: "", pageType: "" });

  const handleChange = (prop) => (event) => {
    const { name, value } = event.target;
    setPage({ ...page, [prop]: event.target.value });
  };

  useEffect(() => {
    getProjectPageDetails(id);
    setPage({ name: "", description: "", pageType: "" });
    setLoading(false);
  }, []);

  // Get Project Page Details

  const getProjectPageDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/page/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setPage({
          name: response.data.name,
          description: response.data.description,
          pageType: response.data.pageType,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Add Project Page Details
  const addProjectPage = async () => {
    setLoading(true);
    let result = await fetch(`${process.env.REACT_APP_API_URL}/page/`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
        body:JSON.stringify({
          id: id,
          name: page.name,
          pageType: page.pageType,
          description: page.description,
          projectId: localStorage.getItem("project_id"),
          createdBy: JSON.parse(localStorage.getItem("user_info")).id,
        }),
      }
    );
    
    if (result.status==200) {   
      navigate("/project/" + localStorage.getItem("project_id") + "/pages")
    } 
    else if(result.status==400){
      alert("Bad request")
      }
    else{
      localStorage.clear("user_info");
      navigate("/");
    }
  };


  const pagetype = [
    { name: "Form", id: "form" },
    { name: "List", id: "list" },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Page</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        "/project/" +
                        localStorage.getItem("project_id") +
                        "/pages"
                      }
                    >
                      Pages
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Create New Page</li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />

          <form role="form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={page.name}
                    onChange={handleChange("name")}
                    onBlur={handleChange("name")}
                  />
                </div>

                <div className="form-group">
                  <label>Page Type</label>
                  <select
                    className="form-control form-select"
                    id="pageType"
                    name="pageType"
                    value={page.pageType}
                    onChange={handleChange("pageType")}
                  >
                    {pagetype.map((page) => {
                      return (
                        <option name={page.id} value={page.id}>
                          {page.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    aria-rowspan={3}
                    value={page.description}
                    onChange={handleChange("description")}
                    onBlur={handleChange("description")}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  onClick={addProjectPage}
                >
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={navigateToPages}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditPage;
