import { useState } from "react";
const Input = ({
  field_id,
  fieldLabel,
  mappedEntityFieldId,
  isMandatoryField,
}) => {
  const [val, setVal] = useState();

  return (
    <div className="form-group ">
      <label htmlFor="exampleInputEmail1">{fieldLabel}</label>
      {isMandatoryField && isMandatoryField == true ? (
        <>
          {" "}
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            required
          />
          <div className="invalid-feedback">
            Please provide a valid {fieldLabel}
          </div>
        </>
      ) : (
        <input
          type="text"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      )}
      <p>{val}</p>
    </div>
  );
};
export default Input;
