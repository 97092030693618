import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function EditEntityField() {
  const { id } = useParams();
  let [loading, setLoading] = useState(true);
  let [entity_field, setEntity] = useState({ name: "", description: "" });

  const navigate = useNavigate();
  const navigateToEntitys = () => {
    // navigate('/project/' + entity_id + '/entity_fields');
    navigate("/entity-fields/" + localStorage.getItem("entity_id"));
  };

  const handleChange = (prop) => (event) => {
    const { name, value } = event.target;
    setEntity({ ...entity_field, [prop]: event.target.value });
  };

  useEffect(() => {
    getEntityFieldDetails(id);
    setEntity({ name: "", description: "" });
    setLoading(false);
  }, []);

  // Get Project Entity Details

  const getEntityFieldDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/entity-field/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setEntity({
          name: response.data.name,
          description: response.data.description,
          entityFieldType: response.data.entityFieldType,
          defaultValue: response.data.defaultValue,
          entityId: localStorage.getItem("entity_id"),
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Add Project Entity Details
  const addEntityField = async () => {
    setLoading(true);
    let result = await fetch(`${process.env.REACT_APP_API_URL}/entity-field/`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      body:JSON.stringify( {
        id: id,
        name: entity_field.name,
        description: entity_field.description,
        entityId: localStorage.getItem("entity_id"),
        entityFieldType: entity_field.entityFieldType,
        defaultValue: entity_field.defaultValue,
      }),
    }
  );
  
  if (result.status==200) {   
    navigate("/entity-fields/" + localStorage.getItem("entity_id"))
  } 
  else if(result.status==400){
    alert("Bad request")
    }
  else{
    localStorage.clear("user_info");
    navigate("/");
  }

  };

  
  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Entity Field</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={"/entities/" + localStorage.getItem("project_id")}
                    >
                      Entities
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={"/entity-fields/" + localStorage.getItem("entity_id")}
                    >
                      Entity Fields
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create New Entity Field
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />

          <form role="form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={entity_field.name}
                    onChange={handleChange("name")}
                    onBlur={handleChange("name")}
                  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    aria-rowspan={3}
                    value={entity_field.description}
                    onChange={handleChange("description")}
                    onBlur={handleChange("description")}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Entity Field Type</label>
                  <input
                    type="text"
                    className="form-control"
                    id="entityFieldType"
                    name="entityFieldType"
                    value={entity_field.entityFieldType}
                    onChange={handleChange("entityFieldType")}
                    onBlur={handleChange("entityFieldType")}
                  />
                </div>
                <div className="form-group">
                  <label>Default Value</label>
                  <input
                    type="text"
                    className="form-control"
                    id="defaultValue"
                    name="defaultValue"
                    value={entity_field.defaultValue}
                    onChange={handleChange("defaultValue")}
                    onBlur={handleChange("defaultValue")}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                <button
                  type="button"
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                  onClick={addEntityField}
                >
                  Update
                </button>
                <button
                  type="button"
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                  onClick={navigateToEntitys}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditEntityField;
