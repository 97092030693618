import { useState } from "react";
const DatePicker = ({
  field_id,
  fieldLabel,
  mappedEntityFieldId,
 
}) => {
  const [val, setVal] = useState();

  return (
    <div className="form-group ">
      <label htmlFor="exampleInputEmail1">{fieldLabel}</label>
     
          {" "}
          <input
            type="date"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
      <p>{val}</p>
    </div>
  );
};
export default DatePicker;
