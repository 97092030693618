import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../btn/btn.css";
import Header from "../common/header";
import NavigationBar from "../common/navbar";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function AddOrganization() {
  const navigate = useNavigate();
  const navigateToOrganizations = () => {
    navigate("/organizations");
  };

  let [organization, setOrganization] = useState({
    name: "",
    registrationNumber: "",
    gstNumber: "",
    organizationAddress: {
      addressLineFirst: "",
      addressLineSecond: "",
      city: "",
      state: "",
      pinCode: "",
      country: "",
    },
  });
  let [organizationErrors, setorganizationErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (name) => (event) => {
    const { name, value } = event.target;
    setOrganization({ ...organization, [name]: value });

    if (
      name === "addressLineFirst" ||
      name === "addressLineSecond" ||
      name === "city" ||
      name === "state" ||
      name === "pinCode" ||
      name === "country"
    ) {
      setOrganization((prevOrganization) => {
        const newOrganization = { ...prevOrganization };
        newOrganization.organizationAddress[name] = value;
        return newOrganization;
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setorganizationErrors(validate(organization));
    setIsSubmit(true);

    // navigateToEntitys();
  };

  // Add Organization

  useEffect(() => {
    if (Object.keys(organizationErrors).length === 0 && isSubmit) {
      if (isSubmit) {
        onSubmitData()
      }
    }
  }, [organizationErrors]);


  const onSubmitData = async () => {
   
    let result = await fetch(`${process.env.REACT_APP_API_URL}/organization/`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
        body:JSON.stringify({
          name: organization.name,
          registrationNumber: organization.registrationNumber,
          gstNumber: organization.gstNumber,
          organizationAddress: {
            addressLineFirst:
              organization.organizationAddress.addressLineFirst,
            addressLineSecond:
              organization.organizationAddress.addressLineSecond,
            city: organization.organizationAddress.city,
            state: organization.organizationAddress.state,
            pinCode: organization.organizationAddress.pinCode,
            country: organization.organizationAddress.country,
            createdBy: JSON.parse(localStorage.getItem("user_info")).id,
          },
        }),
      }
    );
    
    if (result.status==200) {   
      navigate("/organizations")
    } 
    else if(result.status==400){
      alert("Bad request")
      }
    else{
      localStorage.clear("user_info");
      navigate("/");
    }
 
};

  const validate = (values) => {
    const errors = {};
    const regex = /^[0-9\b]+$/;
    if (!values.name) {
      errors.name = " * Name is Required";
    }

    if (!values.registrationNumber) {
      errors.registrationNumber = " * Registration Number is Required";
    }
    //    else if(values.registrationNumber.length < 10  ){
    //     errors.registrationNumber="* Registration Number should be 11 digit"}
    //     else if(values.registrationNumber.length > 12 ){
    //         errors.registrationNumber="* Registration Number should be 11 digit"}
    //     else if(!regex.test(values.registrationNumber)){
    //         errors.registrationNumber="* Only Numbers are allowed"
    //     }
    if (!values.gstNumber) {
      errors.gstNumber = " * GST Number is Required";
    } else if (values.gstNumber.length < 14) {
      errors.gstNumber = "* Registration Number should be 15 digit";
    }

    if (!values.addressLineFirst) {
      errors.addressLineFirst = " * Address Line First is Required";
    }
    if (!values.addressLineSecond) {
      errors.addressLineSecond = " * Address Line Second is Required";
    }
    if (!values.city) {
      errors.city = " * City is Required";
    }
    if (!values.state) {
      errors.state = " * State is Required";
    }
    if (!values.pinCode) {
      errors.pinCode = " * Pincode is Required";
    } else if (values.pinCode.length < 5) {
      errors.pinCode = "* Pincode Number should be 6 digit";
    } else if (values.pinCode.length > 6) {
      errors.pinCode = "* Pincode Number should be 6 digit";
    } else if (!regex.test(values.pinCode)) {
      errors.pinCode = " * Only Numbers are allowed";
    }

    if (!values.country) {
      errors.country = " * Country is Required";
    }

    return errors;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Organization</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create New Organization
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />

          <form role="form" onSubmit={handleSubmit}>
            <h4>Basic Information</h4>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={organization.name}
                    onChange={handleChange("name")}
                  />
                </div>
                <p style={{ color: "red" }}>{organizationErrors.name}</p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Registration Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="registrationNumber"
                      name="registrationNumber"
                      value={organization.registrationNumber}
                      onChange={handleChange("registrationNumber")}
                    />
                  </div>
                  <p style={{ color: "red" }}>
                    {organizationErrors.registrationNumber}
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>GST Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="gstNumber"
                      name="gstNumber"
                      value={organization.gstNumber}
                      onChange={handleChange("gstNumber")}
                    />
                  </div>
                  <p style={{ color: "red" }}>{organizationErrors.gstNumber}</p>
                </div>
              </div>
            </div>

            <div className="row">
              <h4>Address</h4>
              <hr />
              <div className="col-md-6">
                <div className="form-group">
                  <label>Address Line First</label>
                  <input
                    type="text"
                    className="form-control"
                    id="addressLineFirst"
                    name="addressLineFirst"
                    value={organization.organizationAddress.addressLineFirst}
                    onChange={handleChange("addressLineFirst")}
                  />
                </div>
                <p style={{ color: "red" }}>
                  {organizationErrors.addressLineFirst}
                </p>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Address Line Second/Landmark(s)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="addressLineSecond"
                    name="addressLineSecond"
                    value={organization.organizationAddress.addressLineSecond}
                    onChange={handleChange("addressLineSecond")}
                  />
                </div>
                <p style={{ color: "red" }}>
                  {organizationErrors.addressLineSecond}
                </p>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={organization.organizationAddress.city}
                    onChange={handleChange("city")}
                  />
                </div>
                <p style={{ color: "red" }}>{organizationErrors.city}</p>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    value={organization.organizationAddress.state}
                    onChange={handleChange("state")}
                  />
                </div>
                <p style={{ color: "red" }}>{organizationErrors.state}</p>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Pin code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pinCode"
                    name="pinCode"
                    value={organization.pinCode}
                    onChange={handleChange("pinCode")}
                  />
                </div>
                <p style={{ color: "red" }}>{organizationErrors.pinCode}</p>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    name="country"
                    value={organization.organizationAddress.country}
                    onChange={handleChange("country")}
                  />
                </div>
                <p style={{ color: "red" }}>{organizationErrors.country}</p>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                <button
                  type="submit"
                  style={{
                    padding: "5px",
                    marginRight: "5px",
                    borderRadius: "4px",
                  }}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  onClick={navigateToOrganizations}
                  style={{ padding: "5px", borderRadius: "4px" }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddOrganization;
