import { Link, useNavigate, useParams } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import ClockLoader from "react-spinners/ClockLoader";
import Header from "../common/header";
import NavigationBar from "../common/navbar";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function AddProject() {
  const { id } = useParams();
  const navigate = useNavigate();
  // let history = useHistory ();
  const navigateToProjects = () => {
    navigate("/projects/" + localStorage.getItem("organization_id"));
  };
  let [loading, setLoading] = useState(true);
  let [project, setProject] = useState({
    name: "",
    description: "",
    organizationId: "",
    importEntitiesUsingDatabase: false,
    createPages: false,
    duplicateFromProject: false,
    duplicateFromProjectId: '',
    databaseToImport: {
      host: "",
      port: "",
      userName: "",
      password: "",
      databaseName: "",
    },
  });

  const handleChange = (prop) => (event) => {
    const { name, value } = event.target;
    setProject({ ...project, [prop]: event.target.value });
    if (name === "importEntitiesUsingDatabase" || name === "createPages" || name === "duplicateFromProject") {
      setProject({ ...project, [prop]: event.target.checked });
    }
    if (
      name === "databaseName" ||
      name === "host" ||
      name === "password" ||
      name === "port" ||
      name === "userName"
    ) {
      setProject((prevProject) => {
        const newProject = { ...prevProject };
        newProject.databaseToImport[name] = value;
        return newProject;
      });
    }
  };

  const [projects, setProjects] = useState([]);
  
  useEffect(() => {
    if (id) {
      getProjectsDetails(id);
    }
    getProjects();
    setProject({
      name: "",
      description: "",
      organizationId: "",
      importEntitiesUsingDatabase: false,
      duplicateFromProject: false,
      duplicateFromProjectId: "",
      createPages: false,
      databaseToImport: {
        host: "",
        port: "",
        userName: "",
        password: "",
        databaseName: "",
      },
    });
    setLoading(false);
  }, []);

  // Get Project Details

  const getProjectsDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/project/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setProject({
          name: response.data.name,
          description: response.data.description,
          organizationId: localStorage.getItem("organization_id"),
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };


  const getProjects = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/project/organization/all/${localStorage.getItem("organization_id")}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("user_info")).accessToken,
        },
      })
      .then((response) => {
        setProjects(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Add Project Details
  const addProject = async () => {
    debugger;
    setLoading(true);
    var mainbody = {
      name: project.name,
      description: project.description,
      organizationId: localStorage.getItem("organization_id"),
      createdBy: JSON.parse(localStorage.getItem("user_info")).id,
      importEntitiesUsingDatabase: project.importEntitiesUsingDatabase,
      duplicateFromProject: project.duplicateFromProject,
      duplicateFromProjectId: project.duplicateFromProjectId,
      createPages: project.createPages,
    };
    if (project.databaseToImport) {
      mainbody["databaseToImport"] = {
        host: project.databaseToImport.host,
        port: project.databaseToImport.port,
        userName: project.databaseToImport.userName,
        password: project.databaseToImport.password,
        databaseName: project.databaseToImport.databaseName,
      };
    }

    if (id) {
      mainbody["id"] = id;
    }
    // var config = {
    //   method: "post",
    //   url: `${process.env.REACT_APP_API_URL}/project`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "*/*",
    //     Authorization:
    //       "Bearer " + JSON.parse(localStorage.getItem("user_info")).accessToken,
    //   },
    //   data: mainbody,
    // };
    // axios(config);
    // navigateToProjects();
    let result = await fetch(`${process.env.REACT_APP_API_URL}/project`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("user_info")).accessToken,
      },
      body:JSON.stringify(mainbody),
    }
  );
 
  if (result.status==200) {   
    navigate("/projects/" + localStorage.getItem("organization_id"))
  } 
  else if(result.status==400 ||result.status==500){
    alert("Bad request")
    }
  else{
    localStorage.clear("user_info");
    navigate("/");
  }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <NavigationBar />
        <div className="col-md-9">
          <Header />
          <h2>Create Project</h2>
          <hr />

          <div className="row">
            <div className="col-md-9">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {/* <Link to="/dashboard">Dashboard</Link> */}
                    <Link to="/organizations">Organizations</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {/* <a href='/projects'>Projects</a> */}
                    <Link
                      to={
                        "/projects/" + localStorage.getItem("organization_id")
                      }
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Create New Project</li>
                </ol>
              </nav>
            </div>
          </div>
          <hr />

          <form role="form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={project.name}
                    onChange={handleChange("name")}
                    onBlur={handleChange("name")}
                  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    aria-rowspan={3}
                    value={project.description}
                    onChange={handleChange("description")}
                    onBlur={handleChange("description")}
                  ></textarea>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="duplicateFromProject"
                    name="duplicateFromProject"
                    value={project.duplicateFromProject}
                    onChange={handleChange("duplicateFromProject")}
                    onBlur={handleChange("duplicateFromProject")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Duplicate using existing project
                  </label>
                </div>

                <div className="form-group">
                          <select
                            className="form-select"
                            id="duplicateFromProjectId"
                            value={project.duplicateFromProjectId}
                            label="Project Id"
                            onChange={handleChange("duplicateFromProjectId")}
                            style={{ width: "100%" }}
                          >
                            <option>Select project</option>
                            {projects.map((data, index) => {
                              return (
                                <option key={index} value={data.id}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="importEntitiesUsingDatabase"
                    name="importEntitiesUsingDatabase"
                    value={project.importEntitiesUsingDatabase}
                    onChange={handleChange("importEntitiesUsingDatabase")}
                    onBlur={handleChange("importEntitiesUsingDatabase")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Import Entities Using Database
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="createPages"
                    name="createPages"
                    value={project.createPages}
                    onChange={handleChange("createPages")}
                    onBlur={handleChange("createPages")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Create pages
                  </label>
                </div>

                <div className="form-group">
                  <label>Databast Host</label>
                  <input
                    type="text"
                    className="form-control"
                    id="host"
                    name="host"
                    value={
                      project.databaseToImport && project.databaseToImport.host
                    }
                    onChange={handleChange("host")}
                    onBlur={handleChange("host")}
                  />
                </div>
                <div className="form-group">
                  <label>Databast Port</label>
                  <input
                    type="text"
                    className="form-control"
                    id="port"
                    name="port"
                    value={
                      project.databaseToImport && project.databaseToImport.port
                    }
                    onChange={handleChange("port")}
                    onBlur={handleChange("port")}
                  />
                </div>
                <div className="form-group">
                  <label>Databast Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="databaseName"
                    name="databaseName"
                    value={
                      project.databaseToImport &&
                      project.databaseToImport.databaseName
                    }
                    onChange={handleChange("databaseName")}
                    onBlur={handleChange("databaseName")}
                  />
                </div>
                <div className="form-group">
                  <label>Databast Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userName"
                    name="userName"
                    value={
                      project.databaseToImport &&
                      project.databaseToImport.userName
                    }
                    onChange={handleChange("userName")}
                    onBlur={handleChange("userName")}
                  />
                </div>
                <div className="form-group">
                  <label>Databast Password</label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    name="password"
                    value={
                      project.databaseToImport &&
                      project.databaseToImport.password
                    }
                    onChange={handleChange("password")}
                    onBlur={handleChange("password")}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "2rem" }}></div>

            <div className="row">
              <div className="col-md-3">
                <button
                  type="button"
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                  onClick={() => addProject()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                  onClick={navigateToProjects}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ClockLoader
        color="#36d6b9"
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default AddProject;
